import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import Root from "./containers/Root";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers/reducers";
import { unregister } from "./registerServiceWorker";
import { createGlobalStyle, css } from "styled-components";
import { sizes } from "./components/styled-components/Common";

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const GlobalStyle = createGlobalStyle`

html {
  height: 100%;
}

  body {
    height: 100%;
    margin: 0;
    font-family: 'Open sans', sans-serif;
    font-size: 16px;
    overflow-y:scroll;
    *::-ms-clear {
      display: none;
  }

#root {
    min-height: 100%;
    position:relative;
    box-sizing: border-box;
    padding-bottom: 120px; 
    ${media.phone`
      padding: 0;
    `}
}

  
    ${media.tablet`font-size: 14px;`}
    ${media.phone`font-size: 12px;`}
  `;

let store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

ReactDOM.render(
  <>
    <Root store={store} />
    <GlobalStyle />
  </>,
  document.getElementById("root")
);
unregister();
