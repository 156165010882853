export const getMarketString = (market, viewConfig) => {
  return viewConfig && market
    ? viewConfig.reduce((acc, value) => {
        if (!market[value]) return acc;
        if (acc && acc.length > 0) return acc + " " + market[value];
        return market[value];
      }, "")
    : market
      ? market.name
      : "OTHER";
};
