import React from "react";
import { FooterAction } from "./styled-components/StyledFooterExpand";
import { HtmlElements } from "./styled-components/HtmlElements";

/**
 * Presentational component responsible of rendering the Search form. It gets its data from a container.
 * @param {*Object} props
 */
function FooterExpand(props) {
  const { t } = props;

  return (
    <HtmlElements.FocusElement onKeyDown={props.onOpenCloseClick}>
      <FooterAction
        onClick={props.onOpenCloseClick}
        isOpen={props.isOpen}
        show={t("show_disclaimer")}
        hide={t("show_disclaimer")}
      />
    </HtmlElements.FocusElement>
  );
}

export default FooterExpand;
