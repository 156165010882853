import styled from "styled-components";
import React from "react";
import { css } from "styled-components";
import {
  sizes,
  contentWidthIncludedPadding,
  openAdvancedSearchTop,
  closedAdvancedSearchTop,
} from "./Common";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const AppGrid = styled.div`
  display: Grid;
  display: -ms-grid;
  //background: white;
  //background: ${(props) =>
    props.isOpen ? "rgba(118, 120, 122, 0.4);" : "white;"}

  /*IE 11*/
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 109px 226px auto;
  /*******/

  grid-template-columns: 1fr;
  grid-template-rows: 109px 226px auto;

  ${media.tablet`
        -ms-grid-rows: 109px 282px auto;
        grid-template-rows: 109px 282px auto;`}

  ${media.phone`
        -ms-grid-rows: 56px 89px auto;
        grid-template-rows: 56px 73px auto;
        min-height: 100vh;
        ${(props) =>
          props.advancedSearchIsOpen
            ? css`
                background: #1c315b;
                -ms-grid-rows: 56px auto;
                grid-template-rows: 56px auto;
              `
            : ""}
        `}

    width: 100%;
`;

const HeaderGrid = styled.header`
  display: grid;
  display: -ms-grid;

  ${media.phone`
    position: relative; 
    height: 56px;`} background: white;

  /*IE 11*/
  -ms-grid-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;
  /*******/

  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  //height: 56px;
  z-index: 2;
  grid-row: 1/2;

  flex-shrink: 0;
  ${(props) =>
    props.isTestEnvironment
      ? "background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);"
      : "background: #1c315b;"};
`;

const HeaderLeft = styled.div`
  display: flex;
  // background: #1c315b;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;

  justify-content: center;
  align-items: center;
  //padding-left: 140px;

  grid-row: 1/2;
  grid-column: 1/2;
`;
//   ${props =>
//     props.isTestEnvironment ? "background-color: rgb(252, 107, 255);" : ""};
// `;

const HeaderLogoTablet = styled.img`
  display: none;
  cursor: pointer;
  ${media.tablet`display: inline-block;`} ${media.phone`display: none;`};
`;

const HeaderLogoDesktop = styled.img`
  display: inline-block;
  cursor: pointer;
  ${media.tablet`display: none;`};
`;

const HeaderLogoPhone = styled.img`
  display: none;
  cursor: pointer;
  vertical-align: bottom;
  ${media.phone`display: inline-block;`};
`;

const HeaderCenter = styled.div`
  display: flex;
  // background: #1c315b;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;

  grid-row: 1/2;
  grid-column: 2/3;
  justify-content: center;
  align-items: center;
  color: white;
`;
//   ${props =>
//     props.isTestEnvironment ? "background-color: rgb(252, 107, 255);" : ""};
// `;

const Heading = styled.img`
  font-size: 40px;
  width: 191px;
  height: 55px;
  ${media.tablet`font-size: 30px; width: 144px; height: 41px;`} ${media.phone`font-size: 20px; width: 96px; height: 27px;`}
  font-weight: 600;
  cursor: pointer;
`;

const HeaderRight = styled.div`
  display: flex;
  // background: #1c315b;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;

  grid-row: 1/2;
  grid-column: 3/4;
  justify-content: center;
  padding-top: 24px;
  color: white;

  ${media.tablet`
        align-items: center;
        padding-top:0px;
    `} ${media.tablet`
        align-items: center;
        justify-content: flex-end;
        padding: 0px 16px 0px 0px;
    `};
`;
//   ${props =>
//     props.isTestEnvironment ? "background-color: rgb(252, 107, 255);" : ""};
// `;

const AdvancedSearchPanel = styled.div`
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;

  grid-row: 2/3;
  grid-column: 1/4;

  -ms-grid-column-align: center;
  -ms-grid-row-align: start;

  justify-self: center; //x-aksen
  align-self: start; //y-aksen

  box-sizing: border-box;

  padding-top: 12px;
  padding-bottom: 24px;

  max-width: ${contentWidthIncludedPadding.desktop};

  width: 100%;
  border-left: 16px solid white;
  border-right: 16px solid white;

  height: ${(props) => (props.isOpen ? "100%;" : "60%;")} ${media.phone`
        height: 100%;
        border-left: 8px solid white;
        border-right: 8px solid white;
        padding: 8px 0px 8px 0px;
        ${(props) =>
          props.isOpen
            ? css`
                border: 0;
                padding: 0px;
                width: 100%;
                height: 100%;
                background: #1c315b;
              `
            : ""}
    `};
`;

const NewsPanel = styled.main`
  display: flex;
  flex-direction: column;
  position: relative;

  box-sizing: border-box;

  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;

  grid-row: 3/4;
  grid-column: 1/4;

  -ms-grid-column-align: center;
  -ms-grid-row-align: start;

  justify-self: center; //x-aksen
  align-self: start; //y-aksen

  z-index: 1;

  max-width: ${contentWidthIncludedPadding.desktop};
  border-left: 16px solid white;
  border-right: 16px solid white;
  

  ${media.phone`
        position: relative;
        ${(props) => (!props.displayPanelOnMobile ? "display: none;" : "")}
        max-width: ${contentWidthIncludedPadding.phone};
        padding: 0px 0px 16px 0px;
        border-left: 8px solid white;
        border-right: 8px solid white;
    
    `} width: 100%;
  //height: 100%;

  flex: 1 0 auto;
`;

const FooterGrid = styled.footer`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: Grid;
  display: -ms-grid;
  background: white;

  ${media.phone`
        ${(props) => (!props.displayPanel ? "display: none;" : "")}
        grid-row: 4/5; 
        height: 56px;
        position: relative;
        grid-template-rows: 56px; 
        -ms-grid-rows:56px;
    `} background-color: rgba(178, 207, 174, 0.2);

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 102px;

  /*IE 11*/
  -ms-grid-columns: 1fr 1fr 1fr;
  -ms-grid-row: 4; //placement
  -ms-grid-rows: 102px;
  -ms-grid-row-align: end;
  /*******/

  //grid-template-rows: 102px 400px;
  width: 100%;

  grid-row: 4/5;
  z-index: 2;
  align-self: end;

  flex-shrink: 0;
  ${(props) =>
    props.isTestEnvironment
      ? "background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);"
      : "background: #1c315b;"};
`;

const FooterLeft = styled.div`
  display: flex;
  //background: #1c315b;

  /*IE 11*/
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  /*******/

  justify-content: center;
  align-items: center;
  grid-row: 1/2;
  grid-column: 1/2;
  color: white;
`;

const FooterInfoLink = (props) => {
  const Link = styled.a`
    color: white;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `;

  if (props.href != null && props.href.length > 0) {
    return <Link href={props.href}>{props.children}</Link>;
  }
  return <span>{props.children}</span>;
};

const FooterCenter = styled.div`
  display: flex;
  //background: #1c315b;

  -ms-grid-row: 1;
  -ms-grid-column: 2;
  /*******/
  justify-content: center;
  align-items: center;
  grid-row: 1/2;
  grid-column: 2/3;

  color: white;
  cursor: auto;
`;

const FooterRight = styled.div`
  display: flex;

  /*IE 11*/
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  /*******/

  grid-row: 1/2;
  grid-column: 3/4;

  //background: #1c315b;
  align-items: center;
  justify-content: center;
  color: white;

  ${media.tablet`
        align-items: center;
        padding-top: 0px;
    `} ${media.tablet`
        align-items: center;
        justify-content: flex-end;
        padding: 0px 16px 0px 0px;
    `};
`;

const FooterLink = styled.a`
  text-align: left;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  ${media.tablet`
        align-items: center;
        padding-top:0px;

    `}
  ${media.tablet`
        align-items: center;
        justify-content: flex-end;
        padding: 0px 16px 0px 0px;
    `}

    ${media.phone`
    align-items: center;
    //justify-content: flex-end;
    `}
`;

const DisclaimerBox = styled.div`
   
background-color: rgba(0, 0, 0, 0.3);
display: ${(props) => (props.isOpen ? "flex;" : "none;")}

position:fixed;
height:100%;
z-index:11000;
justify-content:center;
align-items: center;




${media.phone`
display: none;
`}

`;

const PageNotFoundBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;

    justify-content: flex-start;
    align-items: center;
    padding-top: 80px;

    margin-top: ${(props) =>
      props.advancedSearchIsOpen
        ? openAdvancedSearchTop
        : closedAdvancedSearchTop.desktop}

    ${media.tablet` 
        margin-top: ${(props) =>
          props.advancedSearchIsOpen
            ? openAdvancedSearchTop
            : closedAdvancedSearchTop.tablet}
    `}

    ${media.phone`
        position: relative;
        border: 0px;
        z-index: 0;
        top: auto;
    `}

    border-top: 24px solid white;
    background: white;
    
    min-height: 50vh;
    height: 100%;
    width: 100%;
    z-index: 10000;
    transition: 200ms all;
`;
const PageNotFoundHeader = styled.h1`
  font-size: 120px;
  font-weight: 600;
  margin: 0 0 0 0;
  ${media.tablet`
        font-size: 90px;
    `} ${media.phone`
        font-size: 70px;
    `};
`;

const PageNotFoundText = styled.p`
  text-align: center;
`;

const ErrorBox = styled.div`
    display: ${(props) => (props.displayError ? "block;" : "none;")}

    margin-top: ${(props) =>
      props.advancedSearchIsOpen ? openAdvancedSearchTop : "-50px;"}

    ${media.tablet` 
        margin-top: ${(props) =>
          props.advancedSearchIsOpen ? openAdvancedSearchTop : "-50px;"}
    `}
    ${media.phone` 
        margin-top: ${(props) =>
          props.advancedSearchIsOpen ? openAdvancedSearchTop : "0px;"}
    `}

    z-index: 99999;
`;
const ErrorHeader = styled.h2`
  font-size: inherit;
  color: #df4747;
  margin-top: 0px;
  margin-bottom: 24px;
`;
const ErrorBody = styled.p`
  margin-top: 0px;
  margin-bottom: 24px;
`;

const StyledApp = {
  AppGrid,
  HeaderGrid,
  HeaderLeft,
  HeaderCenter,
  Heading,
  HeaderRight,
  AdvancedSearchPanel,
  NewsPanel,
  HeaderLogoDesktop,
  HeaderLogoTablet,
  HeaderLogoPhone,
  FooterGrid,
  FooterLeft,
  FooterInfoLink,
  FooterCenter,
  FooterRight,
  FooterLink,
  DisclaimerBox,
  PageNotFoundBox,
  PageNotFoundHeader,
  PageNotFoundText,
  ErrorBox,
  ErrorHeader,
  ErrorBody,
};

export default StyledApp;
