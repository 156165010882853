import styled from "styled-components";
import { sizes } from "./Common";
import { css } from "styled-components";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  color: white;
  font-family: inherit;
  display: inline;
  font-size: inherit;
`;

const Contact = styled.span`
  cursor: pointer;
  white-space: nowrap;
`;
const Language = styled.span`
  cursor: pointer;
  white-space: wrap;
  &:hover {
    text-decoration: underline;
  }
`;
const Link = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  color: white;
`;

const Separator = styled.span`
  text-decoration: none;
  color: white;
  margin-right: 8px;
  margin-left: 8px;
`;

/*
*Hide disclaimer for non-phone users.
*/
const HideDisclaimer = styled.span`
  display: none;

  ${media.phone`
    display:inline;
    //white-space:nowrap;

    `};
`;

const Disclaimer = styled.span`
  text-decoration: none;
  color: white;
`;

export const StyledLanguageAndContact = {
  Container,
  Contact,
  Language,
  Link,
  Separator,
  HideDisclaimer,
  Disclaimer
};
