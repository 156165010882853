import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { StyledSearch } from "./styled-components/StyledSearch";
import LanguageAndContact from "./LanguageAndContact";
import { withTranslation } from "react-i18next";
import DayPicker, { DateTypes } from "./DayPicker";
import { HtmlElements } from "./styled-components/HtmlElements";
import {sizes} from "./styled-components/Common";

class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  isPhone() {
    return this.state.width <= sizes.phone;
  }

  categoryAndMarketShouldBeHidden()  {
    if (!this.props.config.advancedSearchFields) {
      return false;
    }
    return (
      !this.props.config.advancedSearchFields.includes("market") &&
      !this.props.config.advancedSearchFields.includes("category")
    );
  };

  render() {
    const { t, config } = this.props;

    return (
        <StyledSearch.Form
            onSubmit={this.props.handleSubmit}
            isOpen={this.props.isOpen}
            autoComplete="off"
        >
          <StyledSearch.PhoneContent>
            <StyledSearch.PhoneHeader isOpen={this.props.isOpen}>
              {t("advanced_search")}
            </StyledSearch.PhoneHeader>
            <StyledSearch.TopLayer>
              <StyledSearch.Compressed>
                {!this.props.isOpen ? (
                    <Field
                        name="issuer"
                        component={StyledSearch.SearchableSelectContainer}
                        options={this.props.issuerOptions}
                        maxHeight={true}
                        searchable={true}
                        t={t}
                        searchBothKeysAndValues={true}
                        height={this.isPhone() ? 33 : 44}
                        placeholder={t("issuer")}
                    />
                ) : undefined}

                <Field
                    name="messageTitle"
                    component={StyledSearch.SearchInput}
                    placeholder={t("placeholder_search")}
                    minWidth={!this.props.isOpen}
                    margin={!this.props.isOpen}
                />
                {this.props.isOpen ? (
                    <StyledSearch.SearchButton type="submit">
                      <StyledSearch.SearchButtonLabel>
                        {t("search")}
                      </StyledSearch.SearchButtonLabel>
                    </StyledSearch.SearchButton>
                ) : undefined}

                {!this.props.isOpen ? (
                    <StyledSearch.SearchButton type="submit">
                      <StyledSearch.SearchButtonLabel>
                        {t("search")}
                      </StyledSearch.SearchButtonLabel>
                    </StyledSearch.SearchButton>
                ) : undefined}
              </StyledSearch.Compressed>
              <StyledSearch.ClearFormText
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onClearFormClick();
                  }}
                  href=""
              >
                {t("clear_search_form")}
              </StyledSearch.ClearFormText>

              <HtmlElements.FocusElement onKeyDown={this.props.onOpenCloseClick}>
                <StyledSearch.ShowAdvancedSearch
                    role="button"
                    onClick={this.props.onOpenCloseClick}
                    isOpen={this.props.isOpen}
                    show={t("show_as")}
                    hide={t("hide_as")}
                />
              </HtmlElements.FocusElement>
            </StyledSearch.TopLayer>

            <StyledSearch.BottomLayer
                isOpen={this.props.isOpen}
                noSpaceBetween={this.categoryAndMarketShouldBeHidden()}
            >
              <StyledSearch.Issuer_IsActive_grp>
                {this.props.isOpen ? (
                    <Field
                        name="issuer"
                        component={StyledSearch.SearchableSelectContainer}
                        options={this.props.issuerOptions}
                        searchBothKeysAndValues={true}
                        searchable={true}
                        t={t}
                        height={this.isPhone() ? 33 : 34}
                        placeholder={t("issuer")}
                    />
                ) : (
                    ""
                )}

                <Field
                    name="isActive"
                    component={HtmlElements.CheckBox}
                    checked
                    altText={t("active")}
                    tooltipText={t("isActiveIssuer")}
                    onClick={this.props.onIsActiveClick}
                    type="checkbox"
                    text={t("active")}
                />
              </StyledSearch.Issuer_IsActive_grp>
              {!this.categoryAndMarketShouldBeHidden() ? (
                  <StyledSearch.Market_Category_grp>
                    {!config.advancedSearchFields ||
                    (config.advancedSearchFields &&
                        config.advancedSearchFields.includes("market")) ? (
                        <Field
                            name="market"
                            component={StyledSearch.SearchableSelectContainer}
                            searchable={false}
                            t={t}
                            placeholder={t("market")}
                            margin
                            height={this.isPhone() ? 33 : 34}
                            options={this.props.markets}
                        />
                    ) : undefined}
                    {!config.advancedSearchFields ||
                    (config.advancedSearchFields &&
                        config.advancedSearchFields.includes("category")) ? (
                        <Field
                            name="category"
                            component={StyledSearch.SearchableSelectContainer}
                            searchable={true}
                            t={t}
                            // margin
                            height={this.isPhone() ? 33 : 34}
                            placeholder={t("category")}
                            options={this.props.categoryOptions}
                        />
                    ) : undefined}
                  </StyledSearch.Market_Category_grp>
              ) : undefined}
              <StyledSearch.Dates_grp>
                <Field
                    name="fromDate"
                    component={DayPicker}
                    dateType={DateTypes.from}
                    placeholder={t("from_date")}
                />
                <Field
                    name="toDate"
                    component={DayPicker}
                    dateType={DateTypes.to}
                    id="toDate"
                    placeholder={t("to_date")}
                />
              </StyledSearch.Dates_grp>

              <StyledSearch.PhoneSearchButton>
                {t("search")}
              </StyledSearch.PhoneSearchButton>
              <StyledSearch.PhoneClearFormText
                  isOpen={this.props.isOpen}
                  onClick={() => this.props.onClearFormClick()}
              >
                {t("clear_search_form")}
              </StyledSearch.PhoneClearFormText>
            </StyledSearch.BottomLayer>
          </StyledSearch.PhoneContent>
          <StyledSearch.PhoneFooter isOpen={this.props.isOpen}>
            <LanguageAndContact />
          </StyledSearch.PhoneFooter>
        </StyledSearch.Form>
    );
  }
}

AdvancedSearch.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  categoryOptions: PropTypes.array.isRequired,
  issuerOptions: PropTypes.array.isRequired,
  onOpenCloseClick: PropTypes.func.isRequired,
  onIsActiveClick: PropTypes.func.isRequired,
  onClearFormClick: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "advancedSearch",
  initialValues: { isActive: true },
})(withTranslation("translations")(AdvancedSearch));
