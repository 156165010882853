import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * A presentational component that is responsible for drawing the tablerows. It is split
 * into an own component so that we can trigger wether it should re-render or not. Re-rendering is
 * prevented when there is no new data.
 * @param {*Object} props.
 */
class TableData extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.dataIsUpdated;
  }

  componentDidUpdate() {
    this.props.onTableUpdate();
  }

  render() {
    return <tbody>{this.props.tableRows}</tbody>;
  }
}

TableData.propTypes = {
  tableRows: PropTypes.array.isRequired,
  dataIsUpdated: PropTypes.bool.isRequired,
  onTableUpdate: PropTypes.func.isRequired
};

export default TableData;
