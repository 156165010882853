import styled from "styled-components";
import { css } from "styled-components";
import { sizes } from "./Common";

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const DesktopTabletAction = styled.div`
  display: block;
  font-size: 16px;

  ${media.tablet`font-size: 14px;`} ${media.phone`display: none;`};
`;
const PhoneAction = styled.div`
  display: none;
  ${media.phone`display: block;`};
`;

const Icon = styled.img`
  cursor: pointer;
`;

const Actions = styled.div``;

export const StyledMenuAction = {
  DesktopTabletAction,
  PhoneAction,
  Icon,
  Actions
};
