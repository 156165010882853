import React from "react";
import styled, { css } from "styled-components";

import { sizes } from "./Common";
// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const Tooltip = styled.div`
  position: absolute;
  background: #ffffff;
  padding: 8px;
  ${media.phone`display: none;`}

  ${(props) =>
    props.maxWidthDesktop ? "max-width:" + props.maxWidthDesktop + "px;" : ""} 
  ${media.tablet`max-width: ${(props) => props.maxWidthTablet} px;`}
  ${media.phone`max-width: ${(props) => props.maxWidthPhone} px;`}

  width: 100%;
  top: 35px;
  z-index: 100;
  visibility: hidden;

  &:after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 340px;
  width: 100%;
  height: 34px;

  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  font-family: inherit;
  font-size: inherit;
  color: black;

  ${media.phone`
        color:white;
        height: auto;
        margin: 8px 0px 8px 0px;
    `};
`;

const Text = styled.label`
  color: black;
  ${media.phone`color: white;`} cursor: pointer;

  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

const Icon = styled.img`
  display: ${(props) => (props.displayIcon ? "inline" : "none")};
  cursor: pointer;
  padding-right: 8px;
`;

const HiddenInput = styled.input`
  position: absolute;
  left: -1000000px;
`;

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.userClicked = false;
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
  }

  componentDidMount() {
    if (this.props.checked) this.props.input.onChange(true);
  }
  componentWillReceiveProps(nextProps) {
    // If the user didn't click the checkbox, but the check state is different from
    // what it currently is, we trigger an update as if it were updated by clicking.
    if (
      !this.userClicked &&
      nextProps.input.checked !== this.props.input.checked
    ) {
      this.props.onClick(!this.props.input.checked);
      this.props.input.onChange(!this.props.input.checked);
    }
    this.userClicked = false;
  }

  render() {
    const Tooltip = styled(HtmlElements.Tooltip)``;
    const updateCheckBox = () => {
      this.props.onClick(!this.props.input.checked);
      this.props.input.onChange(!this.props.input.checked);
      this.userClicked = true;
    };
    return (
      <Container onClick={updateCheckBox}>
        <FocusElement onKeyDown={updateCheckBox}>
          <Icon
            displayIcon={!this.props.input.checked}
            aria-hidden="true"
            alt={this.props.altText}
            src="/icons/ic-check-box-blank-24-px.svg"
          />
          <Icon
            displayIcon={this.props.input.checked}
            aria-hidden="true"
            alt={this.props.altText}
            src="/icons/ic-check-box-checked-24-px.svg"
          />
        </FocusElement>
        <HiddenInput
          tabIndex="-1"
          {...this.props.input}
          key="2"
          id="isActive"
        />
        <Text htmlFor="isActive">
          {this.props.text}
          <Tooltip tabIndex="-1" role="tooltip">
            {this.props.tooltipText}
          </Tooltip>
        </Text>
      </Container>
    );
  }
}

const CenterContainer = styled.div`
  text-align: center;
`;

/**
 * Element to wrap around components that should recieve tab focus when navigating with keyboard.
 * @param {*} props
 */
const FocusElement = (props) => {
  const Link = styled.a`
    color: inherit;
    text-decoration: none;
  `;
  return (
    <Link
      href="#"
      onKeyDown={(e) => {
        switch (e.keyCode) {
          case 13: //Enter
            e.preventDefault();
            props.onKeyDown();
          default:
        }
      }}
      onClick={(e) => e.preventDefault()}
    >
      {props.children}
    </Link>
  );
};

export const HtmlElements = {
  Tooltip,
  CheckBox,
  CenterContainer,
  FocusElement,
};
