import React from "react";
import { StyledLanguageAndContact } from "./styled-components/StyledLanguageAndContact";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";
import { closeAdvancedSearch } from "../actions/actions";
import { DisclaimerAction } from "./styled-components/StyledDisclaimer";
import { HtmlElements } from "./styled-components/HtmlElements";
import {setLocalLanguage} from "../js/utils";

function LanguageAndContact(props) {
  const { t } = props;

  const _changeLanguage = () => {
    if (i18n.language === "nb" || i18n.language === undefined) {
      i18n.changeLanguage("en");
      setLocalLanguage("en")
    } else {
      i18n.changeLanguage("nb");
      setLocalLanguage("nb")
    }
  };

  const _resetPage = () => {
    props.dispatch(closeAdvancedSearch());
  };

  const href = () => {
    if (i18n.language === "en") return "https://www.euronext.com/en/contact-us";
    else return "https://www.euronext.com/en/contact-us";
  };

  return (
    <StyledLanguageAndContact.Container>
      <StyledLanguageAndContact.Contact>
        <StyledLanguageAndContact.Link target="_blank" href={href()}>
          {t("contact")}
        </StyledLanguageAndContact.Link>
      </StyledLanguageAndContact.Contact>
      {props.config.languages && props.config.languages.length > 1 ? (
        <React.Fragment>
          <StyledLanguageAndContact.Separator>
            |
          </StyledLanguageAndContact.Separator>
          <HtmlElements.FocusElement onKeyDown={_changeLanguage}>
            <StyledLanguageAndContact.Language onClick={_changeLanguage}>
              {t("switch_language")}
            </StyledLanguageAndContact.Language>
          </HtmlElements.FocusElement>
        </React.Fragment>
      ) : undefined}

      <StyledLanguageAndContact.HideDisclaimer>
        <StyledLanguageAndContact.Separator>
          |
        </StyledLanguageAndContact.Separator>
        <StyledLanguageAndContact.Disclaimer
          onClick={(e) => {
            e.preventDefault();
            _resetPage();
          }}
        >
          <DisclaimerAction
            disclaimerLinkTo="/disclaimer"
            disclaimerLinkText={t("show_disclaimer")}
          />
        </StyledLanguageAndContact.Disclaimer>
      </StyledLanguageAndContact.HideDisclaimer>
    </StyledLanguageAndContact.Container>
  );
}

function mapStateToProps(state) {
  const { envJson } = state;

  return {
    config: envJson.env,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(LanguageAndContact)
);
