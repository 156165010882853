import React from "react";
import {
  BodyDisclaimer,
  CloseIcon,
  DisclaimerBox,
  HeaderDisclaimer,
  SubHeaderDisclaimer,
} from "./styled-components/StyledDisclaimer";
import { withTranslation } from "react-i18next";
import i18n from "../i18n";

/**
 * Displays details about a news message inline in the result table.
 * @param {*} props
 */

/*
 onClick={(e) => {
    this.props.dispatch(closeFooterInfo());
}
}
*/
const DisclaimerComp = (props) => {
  const { t } = props;
  const privacyPolicy =
    i18n.language === "en"
      ? "https://www.euronext.com/en/privacy-statement"
      : "https://www.euronext.com/en/privacy-statement";

  return (
    <DisclaimerBox>
      <div>
        <CloseIcon
          id="closeIcon"
          onClick={props.onClick}
          src="/icons/ic-close-black-24-px-1.svg"
        />

        <HeaderDisclaimer>{t("show_disclaimer")}</HeaderDisclaimer>
      </div>

      <SubHeaderDisclaimer>{t("header_disclaimer")}</SubHeaderDisclaimer>

      <BodyDisclaimer>{t("body_disclaimer")}</BodyDisclaimer>

      <SubHeaderDisclaimer>{t("header_copyright_notice")}</SubHeaderDisclaimer>

      <BodyDisclaimer>{t("body_copyright_notice")}</BodyDisclaimer>

      <SubHeaderDisclaimer>{t("header_privacy_policy")}</SubHeaderDisclaimer>

      <BodyDisclaimer>
        <a target="_blank" href={privacyPolicy}>
          {t("body_privacy_policy")}
        </a>
      </BodyDisclaimer>
    </DisclaimerBox>
  );
};

export const Disclaimer = withTranslation("translations")(DisclaimerComp);
