import React from "react";
import PropTypes from "prop-types";
import { StyledMenuAction } from "./styled-components/StyledMenuAction";
import LanguageAndContact from "./LanguageAndContact";

function MenuAction(props) {
  return (
    <StyledMenuAction.Actions>
      <StyledMenuAction.DesktopTabletAction>
        <LanguageAndContact />
      </StyledMenuAction.DesktopTabletAction>
      <StyledMenuAction.PhoneAction>
        <StyledMenuAction.Icon
          alt={"Menu"}
          onClick={props.onMenuClick}
          src={
            props.isOpen
              ? "/icons/ic-close-24-px.svg"
              : "/icons/ic-burger-24-px.svg"
          }
        />
      </StyledMenuAction.PhoneAction>
    </StyledMenuAction.Actions>
  );
}

MenuAction.propTypes = {
  onMenuClick: PropTypes.func.isRequired
};

export default MenuAction;
