import styled from "styled-components";
import React from "react";

import { css } from "styled-components";

import {
  sizes,
  openAdvancedSearchTop,
  closedAdvancedSearchTop,
} from "./Common";
import { HtmlElements } from "./HtmlElements";

//import {FacebookShareButton, FacebookIcon} from 'react-share';
import { FacebookShareButton } from "react-share";
import TwitterShareButton from "react-share/lib/TwitterShareButton";
import LinkedinShareButton from "react-share/lib/LinkedinShareButton";
import { FlexContainer } from "../FlexContainer";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

function isLandscape(orientationState) {
  return orientationState === 90 || orientationState === -90 ? true : false;
}

function isPhone(width) {
  if (width > 736) {
    return false;
  } else {
    return true;
  }
}

const MessageBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    

    margin-top: ${(props) =>
      props.advancedSearchIsOpen
        ? openAdvancedSearchTop + ";"
        : closedAdvancedSearchTop.desktop + ";"}
    
    ${media.tablet` 
        margin-top: ${(props) =>
          props.advancedSearchIsOpen
            ? openAdvancedSearchTop + ";"
            : closedAdvancedSearchTop.tablet + ";"}
    `}

    ${media.phone`
        position: relative;
        border: 0px;
        z-index: 0;
        margin-top: auto;
        font-size: 11px;
    `}

    border-top: 12px solid white;
    transition: 200ms all;
    `;

const HeaderPanel = styled.div`
  background-color: #336699;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 100%;

  height: 42px;

  ${media.tablet`
        padding-left: 12px;
    `} ${media.phone`
        height: 31px;
        padding-left: 8px;
    `}

  font-size: inherit;
  font-weight: 600;
`;

const ContentPanel = styled.div`
  display: Grid;
  display: -ms-grid;
  background-color: rgb(247, 250, 247);
  width: 100%;
  /*IE 11*/
  -ms-grid-columns: 0.9fr 2fr;
  /*******/
  grid-template-columns: 0.9fr 2fr;

  padding: 16px;

  ${media.tablet`
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x:hidden;
    `} ${media.phone`
       
    `};
`;

const AttributesPanel = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 382px;
  padding-right: 24px;

  ${media.tablet`
        display: grid;
        display: -ms-grid;
        width: 100%;


        -ms-grid-columns: 1fr 1fr 1fr;
        -ms-grid-rows: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "1fr 1fr 1fr auto auto;"
            : props.expandedGrid === true ||
              props.attachmentboxIsVisible === true
            ? "1fr 1fr auto auto;"
            : "1fr auto auto;"}  
        /*******/
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "1fr 1fr 1fr auto auto;"
            : props.expandedGrid === true ||
              props.attachmentboxIsVisible === true
            ? "1fr 1fr auto auto;"
            : "1fr auto auto;"}  
        

    `} ${media.phone`
        -ms-grid-columns: 1fr 1fr 1fr;
        -ms-grid-rows: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "1fr 1fr 1fr auto auto auto;"
            : props.expandedGrid === true ||
              props.attachmentboxIsVisible === true
            ? "1fr 1fr auto auto auto;"
            : "1fr 1fr auto auto;"} 
        /*******/
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "1fr 1fr 1fr auto auto auto;"
            : props.expandedGrid === true ||
              props.attachmentboxIsVisible === true
            ? "1fr 1fr auto auto auto;"
            : "1fr 1fr auto auto;"} 
    `};
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  padding-left: 8px;

  ${(props) => (props.visible === false ? "display: none;" : "")} ${media.phone`
        margin-bottom: 8px;
    `};
`;
const TimeBox = styled(InfoBox)`
  ${media.tablet`
-ms-grid-column: 1;
-ms-grid-column-span: 1;
-ms-grid-row: 1;
-ms-grid-row-span: 1;

`};
`;

const IntrumentBox = styled(InfoBox)`
  ${media.tablet`
-ms-grid-column: 1;
-ms-grid-column-span: 1;
-ms-grid-row: 2;
-ms-grid-row-span: 1;

`} ${media.phone`
grid-row: 1/2;
grid-column: 2/3;

-ms-grid-column: 2;
-ms-grid-column-span: 1;
-ms-grid-row: 1;
-ms-grid-row-span: 1;
`};
`;

const MessageIdBox = styled(InfoBox)`
  ${media.tablet`
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
`} ${media.phone`
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
`};
`;

const MarketBox = styled(InfoBox)`
  ${media.tablet`
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
`} ${media.phone`
    grid-row: 2/3;
    grid-column: 2/3;
`};
`;

const IssuerBox = styled(InfoBox)`
  ${media.tablet`
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    `} ${media.phone`
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
    grid-column: 2/4;
    grid-row: 2/3;
    grid-column: 1/2;
    `};
`;
const CategoryBox = styled(InfoBox)`
  ${media.tablet`
        -ms-grid-column: ${(props) =>
          props.instrumentIsVisible || props.attachmentboxIsVisible
            ? "3;"
            : "2;"};
        -ms-grid-row: 2;
        -ms-grid-row-span:2;
        -ms-grid-column-span: 2;

        grid-column: ${(props) =>
          props.instrumentIsVisible || props.attachmentboxIsVisible
            ? "3/4;"
            : "2/3;"};
        grid-row: 2/4;
    `} ${media.phone`
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        -ms-grid-row: ${(props) => (props.expandedGrid === true ? "4;" : "3;")}
        -ms-grid-row-span: 1;

        grid-column: 1/4;
        grid-row: ${(props) => (props.expandedGrid === true ? "4/5;" : "3/4;")}
    `};
`;

const CorrectedVersions = styled(InfoBox)`
  ${media.tablet`
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;

    grid-row: 3/4;
    grid-column: 2/3;
    `} ${media.phone`
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    
    grid-row: 3/4;
    grid-column: 2/3;
    `};
`;

const LanguageBox = styled(InfoBox)`
  ${media.tablet`
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;

    grid-row: 3/4;
    gird-column: 1/2;
    `} ${media.phone`
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;

    grid-row: 3/4;
    gird-column: 1/2;
    `};
`;

const Header = styled.span`
  color: black;
  padding: 1px;

  font-size: inherit;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  ${(props) =>
    props.marginBottom ? "margin-bottom: " + props.marginBottom + ";" : ""};
`;
const Text = styled.span`
  color: black;
  padding: 1px;
`;

const ShareLinks = (props) => {
  const Img = styled.img`
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
  `;

  const Facebook = () => (
    <FacebookShareButton quote={props.title} url={window.location.toString()}>
      <Img src="/icons/facebook.svg" alt={props.fbShareText} />
    </FacebookShareButton>
  );

  const Twitter = () => (
    <TwitterShareButton url={window.location.toString()} title={props.title}>
      <Img src="/icons/twitter.svg" alt={props.twitterShareText} />
    </TwitterShareButton>
  );

  const LinkedIn = () => (
    <LinkedinShareButton url={window.location.toString()} title={props.title}>
      <Img src="/icons/linkedin.svg" alt={props.linkedInShareText} />
    </LinkedinShareButton>
  );

  const Print = () => (
    <div onClick={props.printLink}>
      <Img src="/icons/ic-print-black-24-px.svg" alt={props.PrintText} />
    </div>
  );

  const IconContainer = styled.div`
    display: flex;
    align-items: center;
  `;

  const StandardSpan = styled.span`
    padding: 8px 8px 8px 8px;
    height: 27px;
    cursor: pointer;
    color: rgb(51, 102, 153);
    justify-content: center;
    align-items: center;
    text-align: center;
    &:hover {
      text-decoration: underline;
    }
  `;

  return (
    <IconContainer>
      <Facebook />
      <Twitter />
      <LinkedIn />
      <Print />
      <FlexContainer>
        {isPhone(props.width) && !isLandscape(props.orientationState) ? (
          <StandardSpan onClick={() => props.toggleMessageInFullSize()}>
            {props.buttonText}
          </StandardSpan>
        ) : (
          ""
        )}
      </FlexContainer>
    </IconContainer>
  );
};
const ShareBox = styled(InfoBox)`
  ${media.tablet`
        -ms-grid-row: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "5;"
            : props.attachmentboxIsVisible === true ||
              props.expandedGrid === true
            ? "4;"
            : "3;"}
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;

        grid-row: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "5/6;"
            : props.attachmentboxIsVisible === true ||
              props.expandedGrid === true
            ? "4/5;"
            : "3/4;"}
        grid-column: 1/4;

        ${(props) =>
          props.attachmentboxIsVisible === false ? "align-self: flex-end;" : ""}


    `} ${media.phone`
        -ms-grid-row: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "6;"
            : props.attachmentboxIsVisible === true ||
              props.expandedGrid === true
            ? "5;"
            : "4;"}
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;

        grid-row: ${(props) =>
          props.expandedGrid === true && props.attachmentboxIsVisible === true
            ? "6/7;"
            : props.attachmentboxIsVisible === true ||
              props.expandedGrid === true
            ? "5/6;"
            : "4/5;"}
        grid-column: 1/4;
    `};
`;

const List = styled.ul`
  padding: 1px;
  padding-top: 16px;
  margin: 0px;

  ${media.phone`
        padding-top: 9px;
    `};
`;

const Li = styled.li`
  list-style-type: none;
  list-style-position: outside;
  padding-bottom: 8px;
  ${(props) => (props.pointer ? "cursor: pointer;" : undefined)};
`;

const CategoryLi = (props) => {
  const Container = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    &:hover ${HtmlElements.Tooltip} {
      visibility: visible;
    }
  `;

  const LagringIcon = () => (
    <img src="/icons/lagringspliktig.svg" alt={props.children} />
  );

  const InfoIcon = () => (
    <img src="/icons/info-pliktig-opplysninger.svg" alt={props.children} />
  );

  const Text = styled.span`
    padding-top: 2px;
    padding-left: 8px;
    ${media.tablet`padding-top: 0px;`};
  `;
  const icon =
    props.type === "infoRequired" ? (
      <LagringIcon />
    ) : props.type === "oamMandatory" ? (
      <InfoIcon />
    ) : undefined;

  return (
    <Li>
      <Container>
        {icon}
        <Text>{props.children}</Text>
        <HtmlElements.Tooltip role="tooltip">
          {props.tooltipText}
        </HtmlElements.Tooltip>
      </Container>
    </Li>
  );
};

const FileLi = (props) => {
  const FileLinkContainer = styled.a`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: black;
  `;

  const DownloadIcon = () => (
    <img src="/icons/ic-file-download-black-24-px.svg" alt={props.children} />
  );

  const PdfIcon = () => <img src="/icons/attach-green.svg" alt="ATTACH" />;

  const File = styled.div`
    display: flex;
    align-items: center;
  `;
  const FileName = styled.div`
    padding-left: 8px;
    width: 100%;
    //word-break: break-all;
    &:hover {
      text-decoration: underline;
    }
    margin-right: 2px;
  `;

  return (
    <Li onClick={props.onClick ? props.onClick : props.handleClick} pointer>
      <FileLinkContainer {...props}>
        <File>
          {props.onlyPDFIcon ? <PdfIcon /> : undefined}
          <FileName role="link">{props.children}</FileName>
        </File>
        <DownloadIcon />
      </FileLinkContainer>
    </Li>
  );
};

const FileLink = styled.a`
  text-decoration: none;
  color: black;
`;
const DecoratedText = styled.span`
  display: block;
  text-decoration: underline;
  color: black;
  cursor: pointer;
`;

const AttatchmentBox = styled.div`
  display: flex;
  ${(props) =>
    props.visible === false ? "display: none;" : ""} flex-direction: column;

  margin-bottom: 16px;
  padding: 8px 8px 8px 6px;

  max-width: 352px;

  ${media.tablet`
        -ms-grid-row: ${(props) => (props.expandedGrid === true ? "4;" : "3;")}
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 2;

        grid-row: ${(props) => (props.expandedGrid === true ? "4/5;" : "3/4;")}
        grid-column: 1/3;
    `} ${media.phone`
        max-width: 343px;
        
        -ms-grid-row: ${(props) => (props.expandedGrid === true ? "5;" : "4;")}
        -ms-grid-row-span: 1;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;

        grid-row: ${(props) => (props.expandedGrid === true ? "5/6;" : "4/5;")}
        grid-column: 1/4;
    `}

    background:white;
`;

const Message = styled.div`
  overflow-y: scroll;
  max-height: 880px;
  ${media.tablet`
        max-height: none;
        overflow-y: auto;
    `} ${media.phone`
        max-height: none;
        overflow-y: auto;            
    `}

  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;

  grid-row: 1;
  grid-column: 2/3;

  background: white;
  padding: 16px;
`;

const MessageBodyText = styled.div`
  display: block;
  unicode-bidi: embed;

  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;

  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: inherit;
  overflow-x: hidden;

  ${media.tablet`
    overflow-x:hidden;  
    `}

  //display: inline-block;

    ${media.phone`
    font-size:12px; 
   
    ${(props) =>
      props.showMessageInFullSize
        ? css`
            width: 700px;
          `
        : ""}        
    `}

    ${(props) =>
    props.textFormat === "p"
      ? css`
          font-family: "Courier New", Courier, "Lucida Sans Typewriter",
            "Lucida Typewriter", monospace;
          white-space: pre;
        `
      : ""}
`;

const MessageBodyHeadline = styled.h1`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 0px;

  /***** BREAK-LINE CODE *****/
  -ms-word-break: break-word;
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  /**************************/

  ${media.tablet`
        font-size: 20px;
    `} ${media.phone`
        font-size: 16px;
        margin-bottom: 10px;
    `};
`;

export const StyledNewsMessage = {
  MessageBox,
  HeaderPanel,
  ContentPanel,
  AttributesPanel,
  InfoBox,
  TimeBox,
  IntrumentBox,
  MessageIdBox,
  IssuerBox,
  MarketBox,
  CategoryBox,
  ShareBox,
  ShareLinks,
  AttatchmentBox,
  CorrectedVersions,
  LanguageBox,
  Message,
  Header,
  Text,
  MessageBodyText,
  MessageBodyHeadline,
  List,
  CategoryLi,
  FileLi,
  FileLink,
  DecoratedText,
};
