import React from "react";
import PropTypes from "prop-types";
import Select, {createFilter} from "react-select";
import "../css/react-select.css";
import {getLanguage} from "../js/utils";
import styled, {css} from "styled-components";
import {sizes} from "./styled-components/Common";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const StyledSelect = styled(Select)`
  ${media.phone`
      white-space: nowrap;
      `};
`;

class SearchableSelect extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
          width: window.innerWidth,
      };
      this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    isPhone() {
        return this.state.width <= sizes.phone;
    }

  render() {
    const { name, onChange, value } = this.props.input;

      const onSelectChange = (value) => {
      onChange(value);
    };

      let optionValue = undefined;
      if (value.value != null) {
      const lngLabel = value["label_" + getLanguage()];
      optionValue = {
        value: value.value,
        label: lngLabel != null ? lngLabel : value.label,
      };
    }

    const filterConfig = {
        ignoreCase: true,
        matchFrom: 'start'
    };

      const filterBothKeysAndValues = (option, inputValue) => {
          const labelStartsWith = option.label?.toLowerCase().startsWith(inputValue.toLowerCase());
          const valueStartsWith = option.value?.toLowerCase().startsWith(inputValue.toLowerCase());

          if (labelStartsWith || valueStartsWith) {
              return { ...option, labelStartsWith, valueStartsWith };
          }
          return null;
      }

    return (
      <StyledSelect
      theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          spacing: {
              ...theme.spacing,
              controlHeight: this.props.height ? this.props.height : 33,
          }
         })
      }
      styles={{
          control: (baseStyles) => ({
              ...baseStyles,
              border: "none",
              height: (this.props.height ? this.props.height : '33') + 'px',
              boxShadow: 'none'
          }),
          dropdownIndicator: (base) => ({
              ...base,
              padding: this.isPhone() ? 0 : 8,
          }),
          placeholder: (base) => ({
              ...base,
              lineHeight: "50%",
          }),
      }}
        clearable={true}
        searchable={this.props.searchable}
        maxHeight={this.props.maxHeight}
        name={name}
        placeholder={this.props.placeholder}
        value={optionValue}
        onChange={onSelectChange}
        options={this.props.options}
        filterOption={this.props.searchBothKeysAndValues ? filterBothKeysAndValues : createFilter(filterConfig)}
        noResultsText={this.props.t("not_found")}
        ref={(x) => (this.innerSelect = x)}
      />
    );
  }
}

SearchableSelect.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  searchable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default SearchableSelect;
