import React from "react";
import PropTypes from "prop-types";
import Table from "../components/Table";
import { connect } from "react-redux";
import StyledNews from "../components/styled-components/StyledNews";
import {
  fetchNewsMessage,
  newsIsRendered,
  pageNewsList,
} from "../actions/actions";

import moment from "moment";
import Pagination from "react-js-pagination";
import "../css/pagination.css";
import { withTranslation } from "react-i18next";
import { HtmlElements } from "../components/styled-components/HtmlElements";
import { sizes } from "../components/styled-components/Common";
import i18n from "../i18n";

/**
 * Responsible for handling the news data and sending it in to the Table component that shows the result.
 * @param {*Object} props
 */
class NewsListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.updateTable = true;

    this.toggleUpdateTable = this.toggleUpdateTable.bind(this);
    this.keyHandler = this.keyHandler.bind(this);
    this.getLastPage = this.getLastPage.bind(this);
  }

  toggleUpdateTable() {
    this.updateTable = true;
  }

  componentWillMount() {
    i18n.on("languageChanged", this.toggleUpdateTable);
    window.addEventListener("keydown", this.keyHandler);
  }
  componentWillUnmount() {
    i18n.off("languageChanged", () => this.toggleUpdateTable);
    window.removeEventListener("keydown", this.keyHandler);
  }

  getLastPage() {
    return this.props.newsRows != null
      ? this.props.newsRows.length / this.props.maxResultOnPage
      : 1;
  }

  keyHandler(e) {
    if (
      e.keyCode === 39 &&
      e.shiftKey &&
      this.props.activePage < this.getLastPage()
    ) {
      this.updateTable = true;
      this.props.dispatch(pageNewsList(this.props.activePage + 1));
    } else if (e.keyCode === 37 && e.shiftKey && this.props.activePage > 1) {
      this.updateTable = true;
      this.props.dispatch(pageNewsList(this.props.activePage - 1));
    }
  }

  render() {
    const formatTime = (time) => {
      return moment(time).format("DD.MM.YYYY HH:mm");
    };

    const correctionHandler = (row) => {
      if (row.correctedByMessageId > 0) {
        return (
          <StyledNews.Img
            id={row.id + "_corr_2"}
            src="/icons/ic-corrected-by-24-px.svg"
            title={
              this.props.t("correctedBy_alt_text") + row.correctedByMessageId
            }
            alt={
              this.props.t("correctedBy_alt_text") + row.correctedByMessageId
            }
          />
        );
      }

      if (row.correctionForMessageId > 0) {
        return (
          <StyledNews.Img
            id={row.id + "_corr_1"}
            src="/icons/ic-correction-for-24-px.svg"
            title={
              this.props.t("correctionFor_alt_text") +
              row.correctionForMessageId
            }
            alt={
              this.props.t("correctionFor_alt_text") +
              row.correctionForMessageId
            }
          />
        );
      }
    };

    const onNewsUpdate = () => {
      this.updateTable = false;
      this.props.dispatch(newsIsRendered());
    };

    const loading = !(this.props.newsIsFetched && this.props.issuersIsFetched);

    const updateTable = () => this.updateTable;

    const sliceNews = (newsRows) => {
      if (window.innerWidth >= sizes.desktop)
        return newsRows.slice(
          this.props.maxResultOnPage * (this.props.activePage - 1),
          this.props.maxResultOnPage * this.props.activePage
        );

      return newsRows;
    };

    if (!loading) {
      if (this.props.newsRows != null && this.props.newsRows.length > 0) {
        return [
          <Table
            key={"newsTable"}
            tableRows={sliceNews(this.props.newsRows)}
            onMessageClick={(messageId) =>
              this.props.dispatch(
                fetchNewsMessage(messageId, this.props.history)
              )
            }
            formatTime={formatTime}
            correctionHandler={correctionHandler}
            dataIsUpdated={updateTable}
            advancedSearchIsOpen={this.props.advancedSearchIsOpen}
            onTableUpdate={onNewsUpdate}
            config={this.props.config}
            mapMarkets={this.props.mapMarkets}
          />,

          this.props.newsRows.length > this.props.maxResultOnPage &&
          window.innerWidth >= sizes.desktop ? (
            <HtmlElements.CenterContainer key="2">
              <Pagination
                itemsCountPerPage={this.props.maxResultOnPage}
                activePage={this.props.activePage}
                totalItemsCount={this.props.newsRows.length}
                onChange={(pageNumber) => {
                  this.updateTable = true;
                  this.props.dispatch(pageNewsList(pageNumber));
                }}
                pageRangeDisplayed={4}
              />
            </HtmlElements.CenterContainer>
          ) : (
            ""
          ),
          (this.props.activePage >=
            this.props.newsRows.length / this.props.maxResultOnPage &&
            this.props.newsOverflow) ||
          (window.innerWidth <= sizes.tablet && this.props.newsOverflow) ? (
            <HtmlElements.CenterContainer padding="20px" bold large key="3">
              <StyledNews.MaxResultsReaced>
                {this.props.t("maxResultReached")}
              </StyledNews.MaxResultsReaced>
            </HtmlElements.CenterContainer>
          ) : (
            ""
          ),
        ];
      }
    }
    //reste activepage if new news has been fetched
    if (this.props.activePage !== 1) this.props.dispatch(pageNewsList(1));
    return (
      <StyledNews.NoResultContainer
        advancedSearchIsOpen={this.props.advancedSearchIsOpen}
        loading={loading ? 1 : 0}
      >
        {this.props.isFetchingNews ? (
          <StyledNews.Spinner />
        ) : !loading ? (
          <StyledNews.EmptySearchResult>
            {this.props.t("empty_searchresult")}
          </StyledNews.EmptySearchResult>
        ) : (
          <StyledNews.EmptySearchResult />
        )}
      </StyledNews.NoResultContainer>
    );
  }
}

NewsListContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { news, advancedSearchToggler, issuer, pager, market, envJson } = state;

  return {
    newsRows: news.newsRows,
    newsOverflow: news.overflow,
    newsIsFetched: news.newsIsFetched,
    isFetchingNews: news.isFetchingNews,
    newsIsUpdated: news.newsIsUpdated,
    issuersIsFetched: issuer.issuersIsFetched,
    advancedSearchIsOpen: advancedSearchToggler.isOpen,
    activePage: pager.activePage,
    maxResultOnPage: pager.maxResultOnPage,
    mapMarkets: market.mapMarkets,
    config: envJson.env,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(NewsListContainer)
);
