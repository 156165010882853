import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { closeDisclaimerModal, openDisclaimerModal } from "../actions/actions";
import { connect } from "react-redux";
import FooterExpand from "../components/FooterExpand";
import { withTranslation } from "react-i18next";

class FooterContainer extends Component {
  componentDidUpdate() {
    if (this.props.isOpen) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }

  render() {
    return (
      <FooterExpand
        isOpen={this.props.isOpen}
        onOpenCloseClick={() => {
          this.props.isOpen
            ? this.props.dispatch(closeDisclaimerModal())
            : this.props.dispatch(openDisclaimerModal());
        }}
        t={this.props.t}
      />
    );
  }
}

FooterContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { disclaimerToggler } = state;

  return {
    isOpen: disclaimerToggler.isOpen,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(FooterContainer))
);
