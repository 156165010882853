import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { css } from "styled-components";
import { sizes } from "./Common";

// Iterate through the sizes and create a media template
// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
const tiltedDevice = (...args) => css`
  @media (max-height: 580px) {
    ${css(...args)};
  }
`;

export const DisclaimerAction = ({ disclaimerLinkText, disclaimerLinkTo }) => {
  const Container = styled.span`
    justify-content: center;
    color: white;
    align-items: center;
  `;
  const Text = styled.span`
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `;

  const StyledLink = styled(Link)`
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
      text-decoration-color: #1c315b;
    }
  `;

  // <Link to={disclaimerLinkTo}>{disclaimerLinkText}</Link>
  return (
    //{props.isOpen ? props.hide : props.show}
    <Container>
      <StyledLink to={disclaimerLinkTo}>
        <Text>{disclaimerLinkText} </Text>
      </StyledLink>
    </Container>
  );
};

export const DisclaimerBox = styled.div`
  width: 50%;
  background: white;
  padding: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  //min-height:300px;

  ${tiltedDevice`
      height: 350px;
  `} ${media.phone`
      width: 80%;
  `};
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  float: right;
  margin-top: 6px;
`;

export const HeaderDisclaimer = styled.h1`
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  ${media.phone`
  font-size: 18px;
    `};
`;

export const SubHeaderDisclaimer = styled.h3``;

export const BodyDisclaimer = styled.p``;
