//import css from 'styled-components';

//fakeTablet is used to define TH columns on tablet. It's caused by the resizing of the window, for monospaced 80 column window.
export const sizes = {
  desktop: 1200,
  tablet: 1280,
  fakeTablet: 700,
  phone: 530
};

export const contentWidth = {
  desktop: "1260px",
  tablet: "736px",
  phone: "459px"
};
export const contentWidthIncludedPadding = {
  desktop: "1292px",
  tablet: "768px",
  phone: "475px"
};

export const closedAdvancedSearchTop = {
  desktop: "-135px;",
  tablet: "-190px;"
};
export const openAdvancedSearchTop = "-24px;";
