import React, { Component } from "react";
import { withRouter } from "react-router";
import { closeAdvancedSearch } from "../actions/actions";
import { connect } from "react-redux";
import { Disclaimer } from "../components/Disclaimer";
import { withTranslation } from "react-i18next";

class DisclaimerContainer extends Component {
  componentDidMount() {
    this.props.dispatch(closeAdvancedSearch());
  }

  render() {
    return <Disclaimer onClick={this.props.onClick} />;
  }
}

const mapStateToProps = ({ advancedSearchToggler }) => {
  return {
    isOpen: advancedSearchToggler.isOpen,
  };
};
export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(DisclaimerContainer))
);
