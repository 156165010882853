import React from "react";

import { StyledNewsMessage } from "./styled-components/StyledNewsMessage";

import { withTranslation } from "react-i18next";

/**
 * Displays details about a news message inline in the result table.
 * @param {*} props
 */
function NewsMessage(props) {
  const { t } = props;

  return (
    <StyledNewsMessage.MessageBox
      advancedSearchIsOpen={props.advancedSearchIsOpen}
    >
      <StyledNewsMessage.HeaderPanel role="heading">
        {props.issuer}
      </StyledNewsMessage.HeaderPanel>

      <StyledNewsMessage.ContentPanel>
        <StyledNewsMessage.AttributesPanel
          expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
          attachmentboxIsVisible={props.fileList.length > 0}
        >
          <StyledNewsMessage.TimeBox>
            <StyledNewsMessage.Header>{t("time")}</StyledNewsMessage.Header>
            <StyledNewsMessage.Text>
              {props.publishedTime}
            </StyledNewsMessage.Text>
          </StyledNewsMessage.TimeBox>

          <StyledNewsMessage.MessageIdBox>
            <StyledNewsMessage.Header>
              {t("messageId")}
            </StyledNewsMessage.Header>
            <StyledNewsMessage.Text>{props.messageId}</StyledNewsMessage.Text>
          </StyledNewsMessage.MessageIdBox>

          <StyledNewsMessage.IssuerBox role="contentinfo">
            <StyledNewsMessage.Header>
              {t("issuerId_long")}
            </StyledNewsMessage.Header>
            <StyledNewsMessage.Text>{props.issuerId}</StyledNewsMessage.Text>
          </StyledNewsMessage.IssuerBox>
          {props.instrumentName ? (
            <StyledNewsMessage.IntrumentBox
              visible={props.instrumentName != null}
              role="contentinfo"
            >
              <StyledNewsMessage.Header>
                {t("instrument")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.instrumentName}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.IntrumentBox>
          ) : undefined}

          <StyledNewsMessage.MarketBox role="contentinfo">
            <StyledNewsMessage.Header>{t("market")}</StyledNewsMessage.Header>
            <StyledNewsMessage.Text>{props.markets}</StyledNewsMessage.Text>
          </StyledNewsMessage.MarketBox>

          <StyledNewsMessage.LanguageBox
            visible={props.hasMultipleLanguages}
            role="contentinfo"
          >
            <StyledNewsMessage.Header>
              {t("other_language")}
            </StyledNewsMessage.Header>
            <StyledNewsMessage.Text>
              {props.otherLanguageLink}
            </StyledNewsMessage.Text>
          </StyledNewsMessage.LanguageBox>

          <StyledNewsMessage.CorrectedVersions
            visible={props.hasCorrections}
            role="contentinfo"
          >
            <StyledNewsMessage.Header>
              {t("corrected_versions")}
            </StyledNewsMessage.Header>
            <StyledNewsMessage.Text>
              {props.correctedVersionsLinks}
            </StyledNewsMessage.Text>
          </StyledNewsMessage.CorrectedVersions>

          <StyledNewsMessage.CategoryBox
            role="contentinfo"
            instrumentIsVisible={props.instrumentName != null}
            attachmentboxIsVisible={props.fileList.length > 0}
            expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
          >
            <StyledNewsMessage.Header>{t("category")}</StyledNewsMessage.Header>
            <StyledNewsMessage.Text>{props.category}</StyledNewsMessage.Text>
            <StyledNewsMessage.List>
              {props.messageInfoList}
            </StyledNewsMessage.List>
          </StyledNewsMessage.CategoryBox>

          <StyledNewsMessage.AttatchmentBox
            visible={props.fileList.length > 0}
            expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
          >
            <StyledNewsMessage.Header>{t("attach")}</StyledNewsMessage.Header>
            <StyledNewsMessage.List>{props.fileList}</StyledNewsMessage.List>
          </StyledNewsMessage.AttatchmentBox>

          <StyledNewsMessage.ShareBox
            attachmentboxIsVisible={props.fileList.length > 0}
            expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
          >
            <StyledNewsMessage.Header marginBottom={"5px"}>
              {t("share")}
            </StyledNewsMessage.Header>
            <StyledNewsMessage.ShareLinks
              orientationState={props.orientationState}
              showMessageInFullSize={props.showMessageInFullSize}
              toggleMessageInFullSize={props.toggleMessageInFullSize}
              width={props.width}
              height={props.height}
              buttonText={
                props.showMessageInFullSize
                  ? t("showMessageInCustomSize")
                  : t("showMessageInOriginalSize")
              }
              fbShareText={t("share_fb")}
              linkedInShareText={t("share_linkedin")}
              twitterShareText={t("share_twitter")}
              printText={t("share_print")}
              printLink={props.printLink}
              title={props.title}
            />
          </StyledNewsMessage.ShareBox>
        </StyledNewsMessage.AttributesPanel>
        <StyledNewsMessage.Message>
          <StyledNewsMessage.MessageBodyHeadline>
            {props.title}
          </StyledNewsMessage.MessageBodyHeadline>
          <StyledNewsMessage.MessageBodyText
            showMessageInFullSize={props.showMessageInFullSize}
            textFormat={props.textFormat}
            role="document"
          >
            {props.body}
          </StyledNewsMessage.MessageBodyText>
        </StyledNewsMessage.Message>
      </StyledNewsMessage.ContentPanel>
    </StyledNewsMessage.MessageBox>
  );
}

export default withTranslation("translations")(NewsMessage);
