import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DayPickerInput from "react-day-picker/DayPickerInput";
import "../css/day-picker.css";

import MomentLocaleUtils, { formatDate } from "react-day-picker/moment";
import "moment/locale/nb";

import StyledDayPicker from "./styled-components/StyledDayPicker";
import { browserIsIE, dateParser, getCalendarLanguage } from "../js/utils";
import { withTranslation } from "react-i18next";

import { resetDayPickerFalse, showErrorMessage } from "../actions/actions";
import styled from "styled-components";

const currentYear = new Date().getFullYear();
const staticFromYear = new Date(currentYear - 30, 0).getFullYear();

const Select = styled.select`
  font-size: 16px;
  font-family: inherit;
`;
class YearMonthForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;

    if (name === "month") this.props.onChange({ month: value });
    else this.props.onChange({ year: value });
  }

  render() {
    const locale = getCalendarLanguage();

    const months = this.props.localeUtils.getMonths(locale);

    const allYears = [];
    for (
      let i = staticFromYear;
      i <= this.props.toMonth.getFullYear();
      i += 1
    ) {
      allYears.push(i);
    }

    const visibleYears = [];
    for (
      let i = this.props.fromMonth.getFullYear();
      i <= this.props.toMonth.getFullYear();
      i += 1
    ) {
      visibleYears.push(i);
    }
    return (
      <div className="DayPicker-Caption">
        <Select
          name="month"
          id="dpSelectMonth"
          onChange={this.handleChange}
          value={this.props.month}
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </Select>
        <Select
          name="year"
          id="dpSelectYear"
          onChange={this.handleChange}
          value={this.props.year}
        >
          {allYears.map((year) => (
            <option
              key={year}
              value={year}
              style={{
                display: visibleYears.includes(year) ? "" : "none",
              }}
            >
              {year}
            </option>
          ))}
        </Select>
      </div>
    );
  }
}

class DayPicker extends React.Component {
  constructor(props) {
    super(props);
    this.format = "DD.MM.YYYY";

    var date = undefined;

    if (props.input && props.input.value) {
      date = dateParser(props.input.value);
    }
    date = undefined;
    this.state = {
      month: date ? date.getMonth() : new Date().getMonth(),
      year: date ? date.getFullYear() : new Date().getFullYear(),
    };
  }

  render() {
    const { name, onChange, value } = this.props.input;

    if (this.props.resetDates) {
      this.dayPicker.getInput().value = "";
      this.dayPicker.setState({ value: "" });
    }

    const thisIsAFromDayComponent = () =>
      this.props.dateType === DateTypes.from;
    const thisIsAToDayComponent = () => this.props.dateType === DateTypes.to;
    const fromDayIsEntered = () =>
      this.props.formValues[DateTypes.from] != null;
    const toDayIsEntered = () => this.props.formValues[DateTypes.to] != null;
    const fromDayValue = () => this.props.formValues[DateTypes.from];
    const toDayValue = () => this.props.formValues[DateTypes.to];

    var disabled = null;
    if (thisIsAFromDayComponent()) {
      disabled = {
        before: null,
        after: toDayIsEntered() ? dateParser(toDayValue()) : new Date(),
      };
    } else if (thisIsAToDayComponent()) {
      disabled = {
        before: fromDayIsEntered() ? dateParser(fromDayValue()) : "",
        after: new Date(),
      };
    }

    return (
      <StyledDayPicker.Container
        onKeyDown={(e) => {
          switch (e.keyCode) {
            case 13: //Enter
              if (dateParser(this.dayPicker.getInput().value) === null) {
                onChange("");
                this.dayPicker.setState({ value: "" });
                this.props.dispatch(showErrorMessage("incorrect_date"));
                e.preventDefault();
              }
              this.dayPicker.hideDayPicker();

            default:
          }
        }}
      >
        <DayPickerInput
          inputProps={{
            name: name,
            onFocus: () =>
              (this.dayPicker.getInput().placeholder = this.format),
            onBlur: () => {
              this.dayPicker.getInput().placeholder = this.props.placeholder;
              if (dateParser(this.dayPicker.getInput().value) === null) {
                onChange("");
                this.dayPicker.setState({ value: "" });
              }
            },
          }}
          formatDate={formatDate}
          parseDate={dateParser}
          onDayChange={(day) => {
            this.props.dispatch(resetDayPickerFalse());
            onChange(day ? formatDate(day, "L", "nb") : day);
            const formattedDate = dateParser(day);
            if (formattedDate)
              this.setState({
                month: formattedDate.getMonth(),
                year: formattedDate.getFullYear(),
              });
          }}
          format="L"
          value={value}
          keepFocus={false}
          placeholder={this.props.placeholder}
          dayPickerProps={{
            containerProps: {
              onFocus: (e) => {
                if (
                  !(
                    e.target.id === "dpSelectMonth" ||
                    e.target.id === "dpSelectYear"
                  )
                )
                  this.dayPicker.getInput().focus();
              },
              onBlur: (e) => {
                if (
                  e.target.id === "dpSelectMonth" ||
                  e.target.id === "dpSelectYear"
                )
                  this.dayPicker.getInput().focus();
              },
            },
            captionElement: ({ localeUtils }) => {
              const currentYear = new Date().getFullYear();
              return (
                <YearMonthForm
                  fromMonth={
                    new Date(currentYear - this.props.numPreviousYears, 0)
                  }
                  toMonth={
                    new Date(currentYear + this.props.numFutureYears, 11)
                  }
                  month={this.state.month}
                  year={this.state.year}
                  localeUtils={localeUtils}
                  onChange={(date) => {
                    if (browserIsIE()) this.dayPicker.getInput().focus();
                    this.setState(date);
                  }}
                />
              );
            },
            onMonthChange: (date) => {
              this.setState({
                month: date.getMonth(),
                year: date.getFullYear(),
              });
              if (browserIsIE()) this.dayPicker.getInput().focus();
            },
            month: new Date(this.state.year, this.state.month),
            locale: getCalendarLanguage(),
            localeUtils: MomentLocaleUtils,
            modifiers: {
              disabled,
            },
          }}
          ref={(dayPicker) => (this.dayPicker = dayPicker)}
        />
        <StyledDayPicker.Icon
          alt={this.props.placeholder}
          onClick={() => {
            this.dayPicker.showDayPicker();
            this.dayPicker.getInput().placeholder = this.format;
            this.dayPicker.getInput().focus();
          }}
          src="/icons/ic-date-range-black-24-px.svg"
        />
      </StyledDayPicker.Container>
    );
  }
}

export const DateTypes = Object.freeze({ from: "fromDate", to: "toDate" });

DayPicker.propTypes = {
  dispatch: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  dateType: PropTypes.oneOf([DateTypes.from, DateTypes.to]).isRequired,
};

DayPicker.defaultProps = {
  placeholder: "",
  numFutureYears: 0,
  numPreviousYears: 10,
  forceUpdate: true,
};

function mapStateToProps(state) {
  const { form, dayPickerReset } = state;

  return {
    formValues: form.advancedSearch != null ? form.advancedSearch.values : {},
    resetDates: dayPickerReset.isReset,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(DayPicker)
);
