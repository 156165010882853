import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
  closeAdvancedSearch,
  fetchNews,
  openAdvancedSearch,
  resetDayPickerTrue,
  updateActiveIssuers,
} from "../actions/actions";

import {withRouter} from "react-router";

import AdvancedSearch from "../components/AdvancedSearch";
import {sizes} from "../components/styled-components/Common";

import {reset} from "redux-form";

import {withTranslation} from "react-i18next";
import {dateParser, getLanguage} from "../js/utils";

import {parse} from "query-string";
import {formatDate} from "react-day-picker/moment";

/**
 * Container class that is responsible for displaying the AdvancedSearch presentational component.
 * It fetches news with default filter values at startup, fetches listdata and starts a newsPoller.
 * It will then listen to submit requests by the user and update it's data thereafter.
 */
class AdvancedSearchContainer extends Component {
  render() {
    const {
      mapIssuers,
      categories,
      mapMarkets,
      categoriesIsFetched,
      issuersIsFetched,
      marketsIsFetched,
    } = this.props;

    let categoryOptions = [];
    let issuerOptions = [];
    let marketOptions = [];

    const urlParams = parse(this.props.location.search);

    const formatedValueObj = (values) => {
      const {
        fromDate,
        toDate,
        market,
        category,
        issuer,
        messageTitle,
        isActive,
      } = values;
      const vm = new Map(
        Object.entries(values).filter((entry) => entry[1] != null)
      ); //valueMap

      return {
        fromDate: vm.has("fromDate")
          ? formatDate(dateParser(fromDate), "YYYY-MM-DD")
          : "",
        toDate: vm.has("toDate")
          ? formatDate(dateParser(toDate), "YYYY-MM-DD")
          : "",
        market: vm.has("market")
          ? market.value != null
            ? market.value
            : vm.get("market")
          : "",
        category: vm.has("category")
          ? category.value != null
            ? category.value
            : vm.get("category")
          : "",
        issuer: vm.has("issuer")
          ? issuer.value != null
            ? issuer.value
            : vm.get("issuer")
          : "",
        messageTitle: vm.has("messageTitle") ? messageTitle : "",
        isActive: vm.has("isActive") ? isActive : "",
      };
    };

    const toParamString = (valuesObj) => {
      const issuerId = valuesObj.issuer
        ? valuesObj.issuer.match(/.*_([0-9]+)/i)[1]
        : "";

      return (
        "category=" +
        valuesObj.category +
        "&issuer=" +
        issuerId +
        "&fromDate=" +
        valuesObj.fromDate +
        "&toDate=" +
        valuesObj.toDate +
        "&market=" +
        valuesObj.market +
        "&messageTitle=" +
        encodeURI(valuesObj.messageTitle)
      );
    };

    const onSubmit = (values) => {
      const paramString = toParamString(formatedValueObj(values));
      this.props.dispatch(fetchNews(paramString));
      this.props.history.push("/search?" + paramString);

      if (window.innerWidth <= sizes.phone && this.props.isOpen)
        this.props.dispatch(closeAdvancedSearch());
    };

    if (categoriesIsFetched && issuersIsFetched && marketsIsFetched) {
      categoryOptions = categories.map((category) => {
        return {
          value: category.id,
          label: category["category_" + getLanguage()],
          label_en: category["category_en"],
          label_no: category["category_no"],
        };
      });
      categoryOptions.sort((a, b) => (a.label < b.label ? -1 : 1));
      categoryOptions.unshift({
        value: "",
        label: this.props.t("all_categories"),
        label_en: this.props.t("all_categories", { lng: "en" }),
        label_no: this.props.t("all_categories", { lng: "nb" }),
      });

      mapIssuers.forEach((value, key) => {
        if (this.props.categoryIsActive === 0)
          issuerOptions.push({ value: key, label: value.name });
        else if (this.props.categoryIsActive === 1 && value.isActive)
          issuerOptions.push({ value: key, label: value.name });
      });
      issuerOptions.unshift({
        value: "",
        label: this.props.t("all_issuers"),
        label_en: this.props.t("all_issuers", { lng: "en" }),
        label_no: this.props.t("all_issuers", { lng: "nb" }),
      });

      mapMarkets.forEach((value, key) =>
        key.toLowerCase() !== "other"
          ? marketOptions.push({ value: key, label: value.name })
          : undefined
      );
      marketOptions.unshift({
        value: "",
        label: this.props.t("all_markets"),
        label_no: this.props.t("all_markets", { lng: "nb" }),
        label_en: this.props.t("all_markets", { lng: "en" }),
      });
    }

    return (
      <AdvancedSearch
        isOpen={this.props.isOpen}
        onSubmit={onSubmit}
        onClearFormClick={() => {
          this.props.dispatch(reset("advancedSearch"));
          this.props.dispatch(resetDayPickerTrue());
        }}
        onOpenCloseClick={() => {
          this.props.isOpen
            ? this.props.dispatch(closeAdvancedSearch())
            : this.props.dispatch(openAdvancedSearch());
        }}
        onIsActiveClick={(isActive) =>
          this.props.dispatch(updateActiveIssuers(isActive ? 1 : 0))
        }
        categoryOptions={categoryOptions}
        issuerOptions={issuerOptions}
        markets={marketOptions}
        urlParams={urlParams}
        config={this.props.config}
      />
    );
  }
}

AdvancedSearchContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { issuer, category, market, advancedSearchToggler, language, envJson } =
    state;

  return {
    mapIssuers: issuer.mapIssuers,
    categoryIsActive: issuer.isActive,
    categories: category.categories,
    categoriesIsFetched: category.categoriesIsFetched,
    issuersIsFetched: issuer.issuersIsFetched,
    mapMarkets: market.mapMarkets,
    marketsIsFetched: market.marketsIsFetched,
    isOpen: advancedSearchToggler.isOpen,
    langageIsUpdated: language.langageIsUpdated,
    config: envJson.env,
  };
}

export default withRouter(
  connect(mapStateToProps)(
    withTranslation("translations")(AdvancedSearchContainer)
  )
);
