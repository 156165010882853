import styled from "styled-components";
import { css } from "styled-components";
import { sizes } from "./Common";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;

  width: 100%;
  max-width: 272px;
  height: 34px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  font-size: inherit;
  color: black;

  ${media.tablet`
        max-width: 340px;`} ${media.phone`
        margin: 8px 0px 8px 0px;
        max-width: none;
    `};
`;
const Icon = styled.img`
  position: absolute;
  top: 7px;
  right: 8px;
  cursor: pointer;
`;

const StyledDayPicker = {
  Container,
  Icon,
};
export default StyledDayPicker;
