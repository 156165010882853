import styled, { css } from "styled-components";
import React from "react";
import { sizes } from "./Common";
import SearchableSelect from "../SearchableSelect";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(199, 211, 227, 0.4);
  box-sizing: border-box;
  height: 100%;
  transition: 500ms all;
  width: 100%;

  padding: 16px 40px 16px 40px;
  font-family: inherit;

  ${media.tablet`
        padding: 16px 16px 16px 16px;
        font-size: 14px;

        `} ${media.phone`
        padding: 12px 8px 12px 8px;
        font-size: 12px;
        ${(props) => {
          if (props.isOpen) {
            return css`
              position: relative;
              background: #1c315b;
              width: 100%;
              min-height: 660px;
              height: 100%;
              padding: 24px 24px 40px 24px;
              justify-content: space-between;
            `;
          }
        }}
    `};
`;
const PhoneHeader = styled.span`
  display: none;
  color: white;
  font-size: 24px;
  ${media.phone`
        ${(props) =>
          props.isOpen
            ? css`
                display: inline-block;
              `
            : null}
        padding-bottom: 24px;`};
`;

const TopLayer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
`;
const BottomLayer = styled.div`
  display: ${(props) => (props.isOpen ? "flex;" : "none;")};
  ${(props) => (props.noSpaceBetween ? "" : "justify-content: space-between;")};

  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: 78px;
  font-family: inherit;
  z-index: 10;

  ${media.tablet`
        flex-direction: column;
        flex-wrap: nowrap;
        max-height: 134px;`} ${media.phone`
        justify-content:flex-start;
        flex-direction: column;
        flex-wrap: nowrap;
        max-height: none;
        height: auto;
        margin-top: 8px;`};
`;

const Issuer_IsActive_grp = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 340px;
  font-family: inherit;

  ${media.tablet`
        flex-direction: row;
        max-width: 704px;
        align-items: flex-start;`} ${media.phone`
        flex-direction: column;
        justify-content: flex-start;
    `};
`;

const Market_Category_grp = styled.div`
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  max-width: 340px;
  width: 100%;
  margin-left: 5px;
  font-family: inherit;

  ${media.tablet`
        flex-direction: row;
        max-width: 704px;
        margin-left: 0px;
        align-items: flex-start;
        `} ${media.phone`
        flex-direction: column;
        justify-content: flex-start;
    `};
`;
const Dates_grp = styled.div`
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  max-width: 272px;
  width: 100%;
  margin-left: 5px;
  font-family: inherit;

  ${media.tablet`
        flex-direction: row;
        align-items: flex-start;
        max-width: 704px;
        margin-left: 0px;`} ${media.phone`
        flex-direction: column;
        justify-content: flex-start;
    `};
`;

/**
 * A dummy Component so that we can compress other components togheter
 */
const Compressed = styled.div`
  display: flex;
  box-sizing: content-box;
  width: 100%;
  max-width: 679px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  ${media.phone`
        max-width: initial; 
    `};
`;

class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.Input = styled.input`
      font-family: inherit;

      box-sizing: content-box;
      border: 0;

      height: 44px;
      text-overflow: ellipsis;
      width: 100%;

      max-width: 679px;
      ${(props) => (props.minWidth ? "max-width: 479px;" : "")} ${(props) =>
        props.margin ? "margin: 0 20px 0 20px;" : ""}

      font-size: inherit;
      padding-left: 16px;
      padding-bottom: 0px;
      padding-top: 0px;
      border-radius: 0px;

      &::placeholder {
        color: rgb(102, 102, 102);
      }
      &:-ms-input-placeholder {
        color: rgb(102, 102, 102);
      }

      ${media.tablet`font-size: inherit;`} ${media.phone`
                max-width: initial;
                font-size: inherit;
                padding-left: 8px;
                height: 33px;`};
    `;
  }
  render() {
    return (
      <this.Input
        {...this.props.input}
        minWidth={this.props.minWidth}
        margin={this.props.margin}
        placeholder={this.props.placeholder}
      />
    );
  }
}

const SearchButton = styled.button`
  background: #477857;
  border: 0px;
  color: white;
  height: 44px;
  min-width: 65px;
  display: inline-block;
  box-sizing: content-box;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;

  ${media.tablet`
        min-width: 65px;
    `} ${media.phone`
        height: 33px;
        min-width: 58px;
    `}
    
  padding-bottom: 0px;
  padding-top: 0px;

  font-weight: 500;
  text-align: center;

  &:hover {
    background-color: #3e6a4c;
  }
`;

const SearchButtonLabel = styled.span`
  margin-left: auto;
  margin-right: auto;
`;

const ClearFormText = styled.a`
  font-family: inherit;
  text-decoration: none;

  white-space: nowrap;
  font-weight: 600;
  color: #477857;
  cursor: pointer;
  margin-left: 18px;
  &:hover {
    text-decoration: underline;
  }

  ${media.phone`
        display: none;
    `};
`;

const ShowAdvancedSearch = (props) => {
  const Container = styled.div`
    align-self: center;
    font-family: inherit;
    box-sizing: border-box;
    min-width: 161px;
    margin-left: 18px;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }

    ${media.phone`
            display: none;
        `};
  `;
  const Text = styled.span`
    font-size: inherit;
    font-weight: 600;
    color: #477857;
    padding-right: 8px;
    cursor: pointer;
  `;

  const Icon = styled.img`
    cursor: pointer;
  `;

  return (
    <Container onClick={props.onClick}>
      <Text>{props.isOpen ? props.hide : props.show}</Text>
      <Icon
        alt={props.isOpen ? props.hide : props.show}
        src={
          props.isOpen
            ? "/icons/ic-keyboard-arrow-up-black-24-px.svg"
            : "/icons/ic-keyboard-arrow-down-black-24-px.svg"
        }
      />
    </Container>
  );
};

const SearchableSelectContainer = (props) => {
  const Container = styled.div`
    max-width: 340px;
    width: 100%;
    padding-left: 0px;
    font-size: inherit;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    box-sizing: border-box;
    

    ${media.tablet`font-size: inherit;`};
    ${media.phone`
        ${(props) => (props.margin ? "margin: 8px 0 8px 0;" : "")} 
        height: 33px;
        max-width: none`};
  `;

  return (
    <Container margin={props.margin}>
      <SearchableSelect {...props} />
    </Container>
  );
};
const PhoneSearchButton = styled.button`
  display: none;
  background: #477857;
  border: 0px;
  height: 38px;
  width: 100%;

  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: inherit;

  ${media.phone`
        display: inline-block;
        margin-top: 16px;
    `};
`;

const PhoneContent = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  justify-content: space-between;

  ${media.phone`
        justify-content: flex-start;`};
`;

const PhoneFooter = styled.div`
  display: none;

  ${media.phone`
        ${(props) =>
          props.isOpen
            ? css`
                display: block;
                align-self: center;
              `
            : null}
    
    `};
`;

const PhoneClearFormText = styled.div`
  font-size: 16px;
  color: white;
  font-weight: 600;
  padding-top: 24px;
  align-self: center;
  display: none;

  ${media.phone`
        ${(props) =>
          props.isOpen
            ? css`
                display: block;
              `
            : null}
    
    `};
`;

export const StyledSearch = {
  Form,
  PhoneHeader,
  TopLayer,
  BottomLayer,
  Issuer_IsActive_grp,
  Market_Category_grp,
  Dates_grp,
  Compressed,
  SearchInput,
  SearchButton,
  ClearFormText,
  SearchButtonLabel,
  SearchableSelectContainer,
  ShowAdvancedSearch,
  PhoneSearchButton,
  PhoneClearFormText,
  PhoneFooter,
  PhoneContent,
};
