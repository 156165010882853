import {doRequest} from "./obsvcClient";

export const fetchData = (fetchValues, obsvcPrefix, timeOutInSeconds) => {
  const request = () => {
    return fetchValues.request();
  };
  const receive = (data) => {
    return fetchValues.receive(data);
  };
  const receiveFailed = (error) => {
    return fetchValues.receiveFail(error);
  };

  return async (dispatch) => {
    dispatch(request());

    return doRequest(
        fetchValues.obsvcFilename,
        fetchValues.params,
        true,
        obsvcPrefix,
        timeOutInSeconds
    ).then(
        (data) => {
          dispatch(receive(data));
          return data;
        },
        (error) => {
          dispatch(receiveFailed(error));
          throw error;
        }
    );
  };
};

export const Maintenance = {
  setMaintenance: "SET_MAINTENANCE",
}

export const setMaintenance = (data) => {
  return { type: Maintenance.setMaintenance, data };
}
