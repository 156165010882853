import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import { sizes } from "./Common";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

export const FooterAction = props => {
  const Container = styled.div`
    //justify-content:center;

    display: flex;
    align-items: center;
    font-family: inherit;
    box-sizing: border-box;
    min-width: 161px;
    margin-left: 18px;

    ${media.phone`
            display:none;
        `};
  `;
  const Text = styled.span`
    color: white;
    padding-right: 8px;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `;

  return (
    <Container onClick={props.onClick}>
      <Text>{props.isOpen ? props.hide : props.show}</Text>
    </Container>
  );
};
