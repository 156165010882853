import React from "react";
import PropTypes from "prop-types";
import StyledNews from "./styled-components/StyledNews";
import TableData from "./TableData";
import { sizes } from "./styled-components/Common";
import { withTranslation } from "react-i18next";
import Shiitake from "../shiitake/Shiitake";
import { getLanguage } from "../js/utils";
import { getMarketString } from "../marketUtil";

/**
 * A presentational component that outputs a table element with sticky header
 * @param {*Object} props.
 */
class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyTable: false,
      transition: true,
      width: undefined,
      timeWidth: undefined,
      marketWidth: undefined,
      issuerWidth: undefined,
      titleWidth: undefined,
      corrWidth: undefined,
      attachWidth: undefined,
      categoryWidth: undefined
    };

    this.handleScrollEvent = this.handleScrollEvent.bind(this);
    this.handleResizeEvent = this.handleResizeEvent.bind(this);
  }

  handleScrollEvent() {
    const tableBox = this.table.getBoundingClientRect();
    const tableTopOffset = tableBox.top;

    const stateHandler = topOffset => {
      if (tableTopOffset <= topOffset && this.state.stickyTable === false) {
        this.setState({
          stickyTable: true,
          transition: false,
          width: tableBox.width,
          timeWidth: this.timeTH.getBoundingClientRect().width,
          marketWidth: this.marketTH.getBoundingClientRect().width,
          issuerWidth: this.issuerTH.getBoundingClientRect().width,
          titleWidth: this.titleTH.getBoundingClientRect().width,
          corrWidth: this.corrTH.getBoundingClientRect().width,
          attachWidth: this.attachTH.getBoundingClientRect().width,
          categoryWidth: this.categoryTH.getBoundingClientRect().width
        });
      } else if (
        tableTopOffset > topOffset &&
        this.state.stickyTable === true
      ) {
        this.setState({
          stickyTable: false
        });
      } else if (
        tableTopOffset > topOffset + 2 &&
        this.state.transition === false
      ) {
        this.setState({
          transition: true
        });
      }
    };
    if (tableBox.width <= sizes.phone) {
      stateHandler(0);
    } else {
      stateHandler(-24); //On desktop and tablet there is a 24 white margin on top
    }
  }

  handleResizeEvent() {
    const tableBox = this.table.getBoundingClientRect();
    this.setState({
      width: tableBox.width,
      timeWidth: this.timeTH.getBoundingClientRect().width,
      marketWidth: this.marketTH.getBoundingClientRect().width,
      issuerWidth: this.issuerTH.getBoundingClientRect().width,
      titleWidth: this.titleTH.getBoundingClientRect().width,
      corrWidth: this.corrTH.getBoundingClientRect().width,
      attachWidth: this.attachTH.getBoundingClientRect().width,
      categoryWidth: this.categoryTH.getBoundingClientRect().width
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollEvent);
    window.addEventListener("resize", this.handleResizeEvent);

    if (this.table) {
      this.setState({
        width: this.table.getBoundingClientRect().width,
        timeWidth: this.timeTH.getBoundingClientRect().width,
        marketWidth: this.marketTH.getBoundingClientRect().width,
        issuerWidth: this.issuerTH.getBoundingClientRect().width,
        titleWidth: this.titleTH.getBoundingClientRect().width,
        corrWidth: this.corrTH.getBoundingClientRect().width,
        attachWidth: this.attachTH.getBoundingClientRect().width,
        categoryWidth: this.categoryTH.getBoundingClientRect().width
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollEvent);
    window.removeEventListener("resize", this.handleResizeEvent);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.dataIsUpdated ||
      this.props.advancedSearchIsOpen !== nextProps.advancedSearchIsOpen ||
      nextState !== this.state
    );
  }
  render() {
    const { t, mapMarkets } = this.props;

    const viewConfig =
      this.props.config &&
      this.props.config.markets &&
      this.props.config.markets.listView
        ? this.props.config.markets.listView
        : ["symbol"];

    const rowTags = (this.props.tableRows || []).map(row => (
      <StyledNews.TR
        key={row.id}
        redBackgroundImportant={row["test"]}
        title={row["test"] ? t("this_is_a_test_message") : ""}
      >
        <StyledNews.TD
          boldFirstLine
          center
          width={92}
          phone
          key={row.id + "_1"}
        >
          {this.props.formatTime(row.publishedTime)}
        </StyledNews.TD>
        <StyledNews.TD width={85} desktop key={row.id + "_2"}>
          {mapMarkets &&
          mapMarkets.size > 0 &&
          row.markets &&
          row.markets.length === 1
            ? getMarketString(mapMarkets.get(row.markets[0]), viewConfig)
            : mapMarkets && mapMarkets.size > 0 && row.markets
              ? row.markets.reduce((acc, market) => {
                  //Sometimes this is rendered before markets are fetched therefore it crashes
                  if (acc && acc.length > 0)
                    return (
                      acc +
                      ", " +
                      getMarketString(mapMarkets.get(market), viewConfig)
                    );
                  else
                    return getMarketString(mapMarkets.get(market), viewConfig);
                }, "")
              : ""}
        </StyledNews.TD>
        <StyledNews.TD nowrap width={98} phone key={row.id + "_3"}>
          {row.issuerSign}
        </StyledNews.TD>
        <StyledNews.TD
          pointer
          underlineOnHover
          breakWords
          width={488}
          phone
          bold
          key={row.id + "_4"}
        >
          <StyledNews.MessageLink
            href={"/message/" + row.id}
            onClick={e => {
              e.preventDefault();
              this.props.onMessageClick(row.id);
            }}
          >
            <Shiitake lines={2}>{row.title}</Shiitake>
          </StyledNews.MessageLink>
        </StyledNews.TD>
        <StyledNews.TD
          width={72}
          desktop
          id={row.id + "_5"}
          key={row.id + "_5"}
        >
          {this.props.correctionHandler(row)}
        </StyledNews.TD>
        <StyledNews.TD width={85} center ipad key={row.id + "_6"}>
          {row.numbAttachments > 0 ? row.numbAttachments : ""}
        </StyledNews.TD>
        <StyledNews.TD width={254} desktop key={row.id + "_7"}>
          <Shiitake lines={2}>
            {row.category[0]["category_" + getLanguage()]}
          </Shiitake>
        </StyledNews.TD>
      </StyledNews.TR>
    ));

    return [
      <StyledNews.HContainer
        key={"header" + this.props.dataIsUpdated()}
        stickyTable={this.state.stickyTable}
        advancedSearchIsOpen={this.props.advancedSearchIsOpen}
        aria-hidden="true"
        display="none"
        transition={this.state.transition}
        containerWidth={this.state.width}
      >
        <StyledNews.THDiv maxWidth={this.state.timeWidth} phone key={"time"}>
          {t("time")}
        </StyledNews.THDiv>
        <StyledNews.THDiv
          maxWidth={this.state.marketWidth}
          desktop
          key={"market"}
        >
          {t("market")}
        </StyledNews.THDiv>
        <StyledNews.THDiv
          maxWidth={this.state.issuerWidth}
          phone
          key={"utst.id"}
        >
          {t("issuerId")}
        </StyledNews.THDiv>
        <StyledNews.THDiv
          maxWidth={this.state.titleWidth}
          phone
          key={"message"}
        >
          {t("title")}
        </StyledNews.THDiv>
        <StyledNews.THDiv maxWidth={this.state.corrWidth} desktop key={"corr"}>
          {t("correction")}
        </StyledNews.THDiv>
        <StyledNews.THDiv maxWidth={this.state.attachWidth} ipad key={"attach"}>
          {t("attach_short")}
        </StyledNews.THDiv>
        <StyledNews.THDiv
          maxWidth={this.state.categoryWidth}
          desktop
          key={"category"}
        >
          {t("category")}
        </StyledNews.THDiv>
      </StyledNews.HContainer>,
      <StyledNews.Table
        key={"table"}
        advancedSearchIsOpen={this.props.advancedSearchIsOpen}
        ref={x => (this.table = x)}
      >
        <StyledNews.TableHeader>
          <StyledNews.TableHeaderRow>
            <StyledNews.TH
              scope="col"
              ref={x => (this.timeTH = x)}
              phone
              key={"time"}
            >
              {t("time")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.marketTH = x)}
              desktop
              key={"market"}
            >
              {t("market")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.issuerTH = x)}
              phone
              key={"utst.id"}
            >
              {t("issuerId")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.titleTH = x)}
              phone
              key={"message"}
            >
              {t("title")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.corrTH = x)}
              desktop
              key={"corr"}
            >
              {t("correction")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.attachTH = x)}
              ipad
              key={"attach"}
            >
              {t("attach_short")}
            </StyledNews.TH>
            <StyledNews.TH
              scope="col"
              ref={x => (this.categoryTH = x)}
              desktop
              key={"category"}
            >
              {t("category")}
            </StyledNews.TH>
          </StyledNews.TableHeaderRow>
        </StyledNews.TableHeader>
        <TableData
          tableRows={rowTags}
          dataIsUpdated={this.props.dataIsUpdated()}
          onTableUpdate={this.props.onTableUpdate}
        />
      </StyledNews.Table>
    ];
  }
}

Table.propTypes = {
  tableRows: PropTypes.array.isRequired,
  dataIsUpdated: PropTypes.func.isRequired,
  advancedSearchIsOpen: PropTypes.bool.isRequired,
  formatTime: PropTypes.func.isRequired,
  onMessageClick: PropTypes.func.isRequired,
  correctionHandler: PropTypes.func.isRequired,
  onTableUpdate: PropTypes.func.isRequired
};

export default withTranslation("translations")(Table);
