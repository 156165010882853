import fetch from "cross-fetch";

export const API_URL = () => {
  return fetch("/urls.json", {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then(res => {
    return res.json().then(data => ({
      data: data
    }))
  }).then((res) => {
    sessionStorage.setItem('api', res.data.api_large)
    return res.data.api_large;
  })
}

export const getMaintenance = () => {
  return fetch("/maintenance.json", {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => {
    if (res.status !== 404) {
      return res.json().then(content => {
        return content;
      });
    }
  }).catch((err) => console.log(err));
}

const doFetch = (url, method, headers, body, timeOutInSeconds) => {
  return fetch(url, {
    method: method,
    headers: headers,
    body: body,
    signal: timeOutInSeconds ? AbortSignal.timeout(timeOutInSeconds * 1000) : undefined
  }).then((response) => {
        return onResponseResolved(response)
      }
  )
};

export const doRequest = (
  command,
  data,
  convertToJSON = true,
  obsvcPrefix = true,
  timeOutInSeconds = undefined
) => {
  const headers = new fetch.Headers();

  if (convertToJSON) {
    headers.append("Content-Type", "application/json");
  }

  if (obsvcPrefix) {
    command = "/obsvc/" + command;
  }
  if (command === "/v1/newsreader/env" || sessionStorage.getItem("api") === undefined || sessionStorage.getItem("api") === null) {
    return API_URL().then((api) => {
      const url = api + command;
      return doFetch(url, "POST", headers, convertToJSON ? JSON.stringify(data) : data, timeOutInSeconds)
    })
  } else {
    const method = (command.includes("attachment") || command.includes("pdf")) ? "GET" : "POST";
    const url = sessionStorage.getItem("api") + command;
    return doFetch(url, method, headers, convertToJSON ? JSON.stringify(data) : data).catch((e) => console.log(e))

  }
};

const onResponseResolved = (response) => {
  if (response.status === 500) {
    throw response.status;
  } else if (response.status !== 200 && response.status !== 202) {
    return response.json().then((rJson) => {
      throw rJson;
    });
  } else {
    if (!response._bodyBlob.type.includes("application/json")) {
      return response._bodyBlob;
    }

    return response.json().then((obsvcResponse) => {
      return obsvcResponse.data;
    });
  }
};
