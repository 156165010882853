import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import {
  sizes,
  openAdvancedSearchTop,
  closedAdvancedSearchTop,
} from "./Common";

const onlyDesktop = (...args) => css`
  @media (min-width: ${(sizes.fakeTablet + 100) / 16}em) {
    ${css(...args)};
  }
`;
const onlyTablet = (...args) => css`
  @media (min-width: ${sizes.fakeTablet / 16}em) {
    ${css(...args)};
  }
`;

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const TableHeader = styled.thead`
  background-color: #336699;
`;
const TableHeaderRow = styled.tr`
  background-color: #336699;
`;

const Spinner = () => {
  const Container = styled.div`
    padding-top: 100px;
  `;
  return (
    <Container>
      <img
        src="./icons/loadingspinner.gif"
        width="50px"
        height="50px"
        alt="loading"
      />
    </Container>
  );
};

const Table = styled.table`
/* the position is absolute, so that we can move it when we open/close advanced search */
//position: absolute;
    
    border-top: 12px solid white;
    border-spacing: 0px;
    
    transition: 200ms all;

    width: ${(props) =>
      props.width !== undefined ? props.width + "px;" : "100%;"}
    z-index: ${(props) => (props.hasPriority ? "10;" : "1;")}

    margin-top: ${(props) =>
      props.advancedSearchIsOpen
        ? openAdvancedSearchTop + ";"
        : closedAdvancedSearchTop.desktop + ";"}

    ${media.tablet`
        margin-top: ${(props) =>
          props.advancedSearchIsOpen
            ? openAdvancedSearchTop + ";"
            : closedAdvancedSearchTop.tablet + ";"}
    `}
    ${media.phone`
        position: relative;
        margin-top: 0;
        border: 0px;
        z-index: 0;
        top: auto;
    `}
`;

const EmptySearchResult = styled.div`
  font-size: inherit;
  font-weight: 600;
  min-height: 10vh;
`;

const NoResultContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
      !props.loading
        ? css`
            align-items: flex-start;
            justify-content: flex-start;
            //padding-top: 80px;
          `
        : ""}

    border-top: 24px solid white;

    transition: 200ms all;
    top: ${(props) =>
      props.advancedSearchIsOpen
        ? openAdvancedSearchTop + ";"
        : closedAdvancedSearchTop.desktop + ";"}

    ${media.tablet`
        top: ${(props) =>
          props.advancedSearchIsOpen
            ? openAdvancedSearchTop + ";"
            : closedAdvancedSearchTop.tablet + ";"}
    `}
    ${media.phone`
        position: absolute;
        border: 0px;
        z-index: 0;
        top: auto;
    `}

    width: 100%;
    //min-height: 50vh;
    height: 100%;
    background: white;
    z-index: 10000;
    `;

const TH = styled.th`
  display: none;
  ${(props) => {
    if (props.desktop) return onlyDesktop`display: table-cell;`;
    else if (props.ipad) return onlyTablet`display: table-cell;`;
    else return `display: table-cell;`;
  }} background-color: #336699;
  height: 42px;
  padding-left: 10px;
  color: white;
  text-align: left;
  font-weight: 600;

  ${(props) =>
    props.width !== undefined
      ? "width: " + props.width + ";"
      : ""} ${media.phone`height: 31px;`};
`;

const TD = styled.td`
  display: none;
  ${(props) => {
    if (props.desktop) return onlyDesktop`display: table-cell;`;
    if (props.ipad) return onlyTablet`display: table-cell;`;
    else return `display: table-cell;`;
  }}
  ${(props) => (props.bold ? "font-weight: 600;" : "")}
    ${(props) =>
    props.boldFirstLine ? "&::first-line{font-weight: 600;}" : ""}
    ${(props) =>
    props.width !== undefined ? "width: " + props.width + "px;" : ""}
    ${(props) =>
    props.center !== undefined ? "text-align: center;" : "text-align: left;"}
    ${(props) => (props.pointer ? "cursor: pointer;" : "")}
    ${(props) => (props.nowrap ? "white-space: nowrap;" : "")}
    ${(props) =>
    props.breakWords
      ? css`
          -ms-word-break: break-word;
          word-break: break-word;

          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;
        `
      : ""}
    ${(props) =>
    props.underlineOnHover
      ? css`
          &:hover {
            text-decoration: underline;
          }
        `
      : ""}
    
    box-sizing: border-box;
  padding: 10px;
  max-height: 76px;
`;

const TR = (props) => {
  const StyledTR = styled.tr`
    &:nth-child(odd) {
      //background: rgb(224, 236, 223);
      background: rgba(178, 207, 174, 0.2);
    }
    &:nth-child(even) {
      background-color: white;
    }

    height: 46px;

    ${media.phone`height: 46px;`} width: 100%;
    ${(props) =>
      props.redBackgroundImportant
        ? "background: rgb(265, 133, 133) !important;"
        : ""};
  `;

  return <StyledTR {...props} />;
};

const Tbody = styled.tbody``;

const HContainer = styled.div`
  display: none;
  width: 100%;
  z-index: 10;

  align-items: center;
  background-color: #336699;

  height: 42px;
  ${media.phone`height: 31px;`}

  ${(props) => (props.transition === true ? "transition: 200ms all;" : "")}

    ${(props) =>
    props.stickyTable
      ? css`
          display: flex;
          transition: all 0s ease 0s;
          position: fixed;
          top: 0;
          transition: 0ms all;
          max-width: ${(props) => props.containerWidth + "px;"};
        `
      : css`
          //position: relative;
          border-top: 24px solid white;
          top: ${(props) =>
              props.advancedSearchIsOpen
                ? openAdvancedSearchTop + ";"
                : closedAdvancedSearchTop.desktop + ";"}
            ${media.tablet`
            top: ${(props) =>
              props.advancedSearchIsOpen
                ? openAdvancedSearchTop + ";"
                : closedAdvancedSearchTop.tablet + ";"}
        `} ${media.phone`
            top: auto;
            border: 0;
        `};
        `}
`;

const THDiv = styled.div`
  display: none;
  box-sizing: border-box;
  font-weight: 600;

  ${(props) => {
    if (props.desktop) return onlyDesktop`display: block;`;
    if (props.ipad) return onlyTablet`display: block;`;
    else return `display: block;`;
  }} width: 100%;
  ${(props) =>
    props.maxWidth !== undefined
      ? "max-width: " + props.maxWidth + "px;"
      : ""} ${(props) =>
    props.minWidth !== undefined ? "min-width: " + props.minWidth + "px;" : ""}
  padding-left: 10px;
  color: white;
  text-align: left;
`;

const Img = styled.img`
  //    cursor: ;
`;

const MessageLink = styled.a`
  color: #336699;
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;
const MaxResultsReaced = styled.div`
  font-weight: 600;
  padding: 10px 10px 10px 10px;
  ${media.desktop`font-size: 24px;`}
  ${media.tablet`font-size: 18px;`}
    ${media.phone`font-size: 14px;`}
`;

const StyledNews = {
  TableHeader,
  TableHeaderRow,
  Table,
  Tbody,
  NoResultContainer,
  EmptySearchResult,
  TR,
  TH,
  TD,
  Spinner,
  HContainer,
  THDiv,
  Img,
  MaxResultsReaced,
  MessageLink,
};

export default StyledNews;
