import moment from "moment";
import i18n from "../i18n";

const LOCAL_LANG_KEY = "obnt-local-lang";

/**
 * Creats an array with arg as element. If args is of type Array the just return arg.
 * @param {*Any} arg
 */
export function createArray(arg) {
  return arg === undefined ? [] : Array.isArray(arg) ? arg : [arg];
}

/**
 * Date parser to get the correct date from input field based on format
 * of date string.
 * @param {*} date
 */
export const dateParser = (date) => {
  if (date != null && moment(date, "DD.MM.YYYY", true).isValid()) {
    var check = moment(date, "DD.MM.YYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  } else if (date != null && moment(date, "DDMMYYYY", true).isValid()) {
    var check = moment(date, "DDMMYYYY");

    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");

    return new Date(year, month - 1, day);
  }
  return null;
};

export const setLocalLanguage = (language) => {
  if (sessionStorage) {
    sessionStorage.setItem(LOCAL_LANG_KEY, language);
  }
};

export const getLocalLanguage = () => {
  if (sessionStorage) {
    return sessionStorage.getItem(LOCAL_LANG_KEY);
  }
};

export const getLanguage = () => (i18n.language === "en" ? "en" : "no");
export const getCalendarLanguage = () => (i18n.language === "en" ? "en" : "nb");
export const browserIsIE = () =>
  window.navigator && window.navigator.msSaveOrOpenBlob;
