import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { openAdvancedSearch, closeAdvancedSearch } from "../actions/actions";
import MenuAction from "../components/MenuAction";

/**
 * Container class that is responsible for displaying the AdvancedSearch presentational component
 * in phone mode.
 */
class MenuActionContainer extends Component {
  render() {
    return (
      <MenuAction
        isOpen={this.props.isOpen}
        onMenuClick={() => {
          this.props.isOpen
            ? this.props.dispatch(closeAdvancedSearch())
            : this.props.dispatch(openAdvancedSearch());
        }}
      />
    );
  }
}

MenuActionContainer.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { advancedSearchToggler } = state;

  return {
    isOpen: advancedSearchToggler.isOpen
  };
}

export default connect(mapStateToProps)(MenuActionContainer);
